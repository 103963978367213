export function fetchData(start, stop, callback) {
  const hostUrl = "https://talentbankai.com/cvs/search";

  let uuid = sessionStorage.getItem("uuid");
  let step2data = sessionStorage.getItem("step2data");

  let formData = new FormData();
  let title = {
    1: "load_type",
    2: "technologies",
    3: "years_of_experience",
    4: "domains",
    5: "name",
    6: "email",
    7: "amount_of_developers",    
  };

  if (stop === 2) {
    let fullData = {};
    for (let i = 1; i <= 2; i++) {
      let data = JSON.parse(sessionStorage.getItem(`${i}`));

      let dataFiltered = Object.entries(data).filter(
        (item) => item[1] === true || item[1].length > 0
      );

      let dataValues = dataFiltered.map((item) => {
        return item[0] === "Other" || item[0] === "type" ? item[1] : item[0];
      });

      formData.set(`${title[i]}`, dataValues.toString());

      fullData[i] = dataValues;
    }

    let fullString = fullData[1].concat(fullData[2]).toString();

    if (fullString !== step2data) {
      sessionStorage.setItem("step2data", fullString);
      submit();
    } else {
      callback()
      return};

  } else {
    for (let i = start; i <= stop; i++) {
      let data = JSON.parse(sessionStorage.getItem(`${i}`));

      let dataFiltered = Object.entries(data).filter(
        (item) => item[1] === true || (item[1] != null && item[1].length > 0)
      );

      let dataValues = dataFiltered.map((item) => {
        return item[0] === "Other" || item[0] === "type" ? item[1] : item[0];
      });

      formData.set(`${title[i]}`, dataValues.toString());
    }

    formData.set(`uuid`, uuid);

    submit();
  }

  async function submit() {
    let response = await fetch(`${hostUrl}`, {
      method: "POST",
      body: formData,
    });

    if (response.ok && callback) {
      let result = await response.json();
      sessionStorage.setItem("uuid", result.uuid);
      callback()

    } else {
      console.log("HTTP Error: " + response.status);
    }
  }
}
