import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDarkMode: localStorage.getItem("theme") === "dark" ? true : false,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      const newIsDarkMode = !state.isDarkMode;
      state.isDarkMode = newIsDarkMode;
    },
    setThemeState: (state,action) => {
      state.isDarkMode = action.payload;
    },
  },
});

export const { toggleTheme, setThemeState } = themeSlice.actions;
export default themeSlice.reducer;
