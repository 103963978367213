import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";

import arrow from "./../../../../img/Survey/arrow-left-blue.svg";
import check from "./../../../../img/Survey/icons/check-white.svg";
import error from "./../../../../img/Survey/icons/error.svg";

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./style.scss";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1.5,
    marginLeft: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : "rgba(59, 135, 255, 1)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 21,
    height: 21,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#DEDFE1" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function Step4() {
  let storage = JSON.parse(sessionStorage.getItem("4"));
  let domainsLength = 0;

  const [shake, setShake] = React.useState(false);
  const [overflow, setOverflow] = React.useState(false);
  const [empty, setEmpty] = React.useState(true);
  const [state, setState] = React.useState(
    storage || {
      Blockchain: false,
      Fintech: false,
      Healthcare: false,
      "Media/Art": false,
      PropTech: false,
      Education: false,
      "AI/BigData": false,
      Gaming: false,
      Other: "",
    }
  );

  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef(null);
  const btnRef = useRef(null);
  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      leftArrowRef.current.click();
    }
    if (event.key === 'ArrowRight') {
      rightArrowRef.current.click();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (isFocused) {
      btnRef.current.classList.add("on");
      inputRef.current.classList.remove("added");
      inputRef.current.placeholder = "Type your answer";
      inputRef.current.style.paddingLeft = "20px";
      inputRef.current.style.paddingRight = "50px";
      inputRef.current.classList.add("focused");
    } else if (!state["Other"]) {
      btnRef.current.classList.remove("on");
      inputRef.current.classList.remove("added");
      inputRef.current.placeholder = "Other";
      inputRef.current.classList.remove("focused");
    } else if (state["Other"]) {
      btnRef.current.classList.remove("on");
      inputRef.current.classList.add("added");
      inputRef.current.style.paddingLeft = "52px";
      inputRef.current.style.paddingRight = "10px";
      inputRef.current.classList.remove("focused");
    }
    // eslint-disable-next-line
  }, [isFocused]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      inputRef.current.blur();
    }
  };

  useEffect(() => {

    // eslint-disable-next-line
    domainsLength = Object.values(state).filter((el) => el === true || (typeof el === "string" && el.trim().length !==0)).length;
    sessionStorage.setItem("4", JSON.stringify(state));

    if (
      domainsLength > 1
    ) {
      setShake(true);
      setOverflow(true);
      setTimeout(() => {
        setShake(false);
      }, 1200);
    } else setOverflow(false);

    if (state && (Object.values(state).includes(true) ||
    state["Other"].length >= 2) && domainsLength === 1) {
      setEmpty(false);
    } else setEmpty(true);
  }, [state]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className="flex w-full flex-col items-center gap-8">
      <FormGroup className="step-4 w-full flex justify-center items-center flex-wrap gap-x-2 gap-y-4 order-2 md:order-1">
        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["Blockchain"] === true ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
        
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="Blockchain"
                name="Blockchain"
                checked={state["Blockchain"]}
              />
            }
            label="Blockchain"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["Fintech"] === true ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="Fintech"
                name="Fintech"
                checked={state["Fintech"]}
              />
            }
            label="Fintech"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["Healthcare"] === true ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="Healthcare"
                name="Healthcare"
                checked={state["Healthcare"]}
              />
            }
            label="Healthcare"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["Media/Art"] === true ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="Media/Art"
                name="Media/Art"
                checked={state["Media/Art"]}
              />
            }
            label="Media/Art"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["PropTech"] === true ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="PropTech"
                name="PropTech"
                checked={state["PropTech"]}
              />
            }
            label="PropTech"
          />
          
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["Education"] === true ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="Education"
                name="Education"
                checked={state["Education"]}
              />
            }
            label="Education"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["AI/BigData"] === true ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="AI/BigData"
                name="AI/BigData"
                checked={state["AI/BigData"]}
              />
            }
            label="AI/BigData"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["Gaming"] === true ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="Gaming"
                name="Gaming"
                checked={state["Gaming"]}
              />
            }
            label="Gaming"
          />
        </div>
        
        <div
          className={`hover:shadow hover:bg-softBlue other-wrap ${
            state["Other"].length > 0 ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <input
            ref={inputRef}
            className="block w-full h-full pl-5 pr-3 outline-none placeholder:text-blackPrimary placeholder:text-[15px] text-[15px] font-bold rounded-lg border-0 bg-transparent"
            name="Other"
            id="Other4"
            type="text"
            value={state["Other"]}
            placeholder="Other"
            autoComplete="off"
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => setIsFocused(false)}
            onKeyDown={handleKeyPress}
            onChange={(event) => {
              setState({
                ...state,
                [event.target.name]: event.target.value,
              });
            }}
          />

          <p className="hidden absolute left-[22px] w-[18px] h-[18px] justify-center items-center bg-blue rounded-sm pointer-events-none">
            <img
              className="w-[14px] h-[12px]"
              src={check}
              width={14}
              height={12}
              alt="check"
            />
          </p>

          <div
            ref={btnRef}
            className="hidden absolute z-10 inset-y-0 right-1 items-center"
          >
            <button
              id="btn"
              className="relative w-[40px] h-[40px] flex justify-center items-center bg-blue rounded-lg hover:bg-darkBlue transition-colors"
            >
              <img
                className="w-[14px] h-[12px]"
                src={check}
                width={14}
                height={12}
                alt="check"
              />
            </button>
          </div>
        </div>
      </FormGroup>

      <p className={`${shake ?"animated-button ":""}h-10 w-full max-w-[280px] px-2 flex items-center justify-center rounded-xl bg-softYellow text-sm order-1 md:order-2 shadow-sm whitespace-nowrap dark:text-themeBlackText`}>
        <img
          className={`${overflow ? "overflow " : ""}w-auto h-[20px] me-2`}
          src={error}
          width={20}
          height={20}
          alt="React"
        />
        <span className="font-bold">Please,</span>&nbsp;choose only one domain
      </p>

      <p
        className={`${shake ? "animated-button ":""}h-10 w-full max-w-[280px] px-2 flex items-center justify-center rounded-xl bg-softYellow text-sm order-3 md:hidden shadow-sm whitespace-nowrap dark:text-themeBlackText`}
      >
        <img
          className={`${overflow ? "overflow " : ""}w-auto h-[20px] me-2`}
          src={error}
          width={20}
          height={20}
          alt="React"
        />
        <span className="font-bold">Please,</span>&nbsp;choose only one domain
      </p>


      <div className="w-full flex justify-between items-center order-3 md:order-3">
        <NavLink
          ref={leftArrowRef}
          to={"/survey/3"}
          className="h-12 w-12 flex items-center justify-center shrink-0 rounded-xl bg-white transition-colors hover:bg-lightBlue border border-blue"
        >
          <img
            className="-translate-x-[2px]"
            src={arrow}
            width={12}
            height={20}
            alt="arrow"
          />
        </NavLink>
        <NavLink
          to={"/survey/5"}
          className={`${
            empty ? "pointer-events-none opacity-80" : ""
          } mx-auto h-12 w-28 flex items-center justify-center shrink-0 rounded-xl font-medium text-white bg-blue transition-colors hover:bg-darkBlue`}
        >
          OK
        </NavLink>
        <NavLink 
          ref={rightArrowRef}
          disabled={empty}
          to={!empty ? "/survey/5": undefined }
          className={`${
            empty ? "pointer-events-none opacity-40" : ""
          } h-12 w-12 flex items-center justify-center shrink-0 rounded-xl bg-white transition-colors hover:bg-lightBlue border border-blue`}
        >
          <img
            className="translate-x-[2px] rotate-180"
            src={arrow}
            width={12}
            height={20}
            alt="arrow"
          />
        </NavLink>
      </div>
    </div>
  );
}
