import React from "react";
import "./styles.css";
import adam from "./../../img/adam.webp";
import gabriel from "./../../img/gabriel.webp";
import tracy from "./../../img/tracy.jpg";
import han from "./../../img/han.jpg";

const Hear = () => {
  return (
    <ul className="hear flex px-5 py-7 xl:px-0">
      <li
        className="card h-fit flex flex-col p-7 pt-9 -mr-3 w-[320px] rounded-2xl border border-border text-lg bg-blue text-white -rotate-[4deg]"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
        data-aos-delay="200"
        data-aos-offset="50"
        data-aos-once="false"
      >
        <div className="w-[70px] h-[70px] mb-5 overflow-hidden rounded-full">
          <img
            className="w-[70px] h-[70px] object-cover"
            src={han}
            width={70}
            height={70}
            alt="han"
          />
        </div>
        <p className="dark:text-themeBlackText">
          We have worked with DevBrother for almost a year on several different
          projects. The team is responsive, caters to our needs, and
          communicates extremely well. Their skill-level and commitment to
          project success is commendable and their rates are competitive.
          Overall, the services DevBrother offers is what we hoped for in an
          ideal development partner.
        </p>
        <p className="mt-5 text-3xl font-Author font-semibold whitespace-nowrap dark:text-themeBlackText">
        Han Kao
        </p>
        <p className="text-lg">CEO / Founder, Crypto Briefing</p>
      </li>

      <li
        className="card h-fit flex flex-col p-7 pt-9 w-[320px] rounded-2xl border border-border text-lg bg-white dark:bg-themeBlackText rotate-[0.5deg] relative z-[1] -mr-3"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
        data-aos-delay="300"
        data-aos-offset="50"
        data-aos-once="false"
      >
        <div className="w-[70px] h-[70px] mb-5 overflow-hidden rounded-full">
          <img
            className="w-[70px] h-[70px] object-cover"
            src={tracy}
            width={70}
            height={70}
            alt="tracy"
          />
        </div>
        <p>
          DevBrother successfully implemented new features, improved the
          existing features' UI/UX design, and delivered high-quality game art
          components. Their highly skilled experts met all the planned
          milestones on time. They were also responsive, collaborative, and
          communicative via email and meetings.
        </p>
        <p className="mt-5 text-3xl font-Author font-semibold">Tracy Chang</p>
        <p className="text-lg dark:text-themeGray">CEO, Tradelite Solutions</p>
      </li>

      <li
        className="card h-fit flex flex-col mt-5 -ml-3 p-7 pt-9 w-[320px] rounded-2xl border border-border text-lg bg-blue text-white relative z-[1]"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
        data-aos-delay="400"
        data-aos-offset="50"
        data-aos-once="false"
      >
        <div className="w-[70px] h-[70px] mb-5 overflow-hidden rounded-full">
          <img
            className="w-[70px] h-[70px] object-cover"
            src={gabriel}
            width={70}
            height={70}
            alt="gabriel"
          />
        </div>
        <p className="dark:text-themeBlackText">
          The team was very flexible in working with the demands of a niche
          company. Their expertise also shone through as they pushed back on
          some of the requests that they knew would deter the progress of the
          project. The company appreciated their honesty as a huge asset.
        </p>
        <p className="mt-5 text-3xl font-Author font-semibold dark:text-themeBlackText">Gabriel Afana</p>
        <p className="text-lg">CEO, Ticketing Platform</p>
      </li>
      
      <li
        className="card h-fit flex flex-col -ml-3 p-7 pt-9 w-[320px] rounded-2xl border border-border dark:bg-themeBlackText text-lg bg-white rotate-[0.5deg] relative z-[1]"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
        data-aos-delay="500"
        data-aos-offset="50"
        data-aos-once="false"
      >
        <div className="w-[70px] h-[70px] mb-5 overflow-hidden rounded-full">
          <img
            className="w-[70px] h-[70px] object-cover"
            src={adam}
            width={70}
            height={70}
            alt="adam"
          />
        </div>
        <p>
          DevBrother's (TalentBank) strong development skills allow them to
          provide incredible solutions to the company, which enable them to meet
          expectations. The client highly appreciates their transparent and
          understanding approach. Above all, they provide precise estimates, and
          they work in a timely manner.
        </p>
        <p className="mt-5 text-3xl font-Author font-semibold">Adam Szajcz</p>
        <p className="text-lg dark:text-themeGray">CEO, FAT moves UG</p>
      </li>
    </ul>
  );
};

export default Hear;
