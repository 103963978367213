import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { fetchData } from "./../../../../functions/fetchData";

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import arrow from "./../../../../img/Survey/arrow-left-blue.svg";
import arrowRightBlack from "./../../../../img/Survey/icons/arrow-right.svg";
import "./style.scss";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1.5,
    marginLeft: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : "rgba(59, 135, 255, 1)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 21,
    height: 21,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#DEDFE1" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function Step7() {
  let storage = JSON.parse(sessionStorage.getItem("7"));

  const navigate = useNavigate();
  const empty = false;
  // const [empty, setEmpty] = React.useState(true);
  const [state, setState] = React.useState(
    storage || {
      type: null,
    }
  );

  const [clicked, setClicked] = useState(false);
  const handleFetchData = () => {
    if (!clicked) {
      setClicked(true);
      fetchData(1, 7, () => navigate("/hiring-call"));
      setTimeout(() => setClicked(false), 100);
    }
  };

  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      leftArrowRef.current.click();
    }
    if (event.key === "ArrowRight") {
      rightArrowRef.current.click();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("7", JSON.stringify(state));
    // if (state["type"]) {
    //   setEmpty(false);
    // } else setEmpty(true);
  }, [state]);

  const handleChange = (event) => {
    setState({
      type: event.target.value,
    });
  };  

  return (
    <div className="step-7 max-w-[480px] flex w-full flex-col items-center gap-8">
      <FormControl>
        <RadioGroup
          className="w-full flex justify-center items-center flex-wrap gap-x-2 gap-y-4"
          aria-labelledby="radio-buttons-group"
          name="radio-buttons-group"
          value={state["type"]}
          onChange={handleChange}
        >
          <div
           onClick={handleFetchData}            
            className={`hover:shadow hover:bg-softBlue ${
              state["type"] === "1"
                ? "border-softBlue bg-softBlue"
                : "border-borderGray bg-lightGray"
            } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
          >
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <IOSSwitch
                  className="xl:text-lg"
                  onChange={handleChange}
                  value="1"
                  name="1"
                  checked={state["type"] === "1"}
                />
              }
              label="1"
            />
            <img
              className="absolute w-auto h-5 top-1/2 right-[20px] -translate-y-1/2 pointer-events-none"
              src={arrowRightBlack}
              width={20}
              height={20}
              alt="arrow right"
            />
          </div>

          <div
          onClick={handleFetchData}
            className={`hover:shadow hover:bg-softBlue ${
              state["type"] === "2-5"
                ? "border-softBlue bg-softBlue"
                : "border-borderGray bg-lightGray"
            } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
          >
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <IOSSwitch
                  className="xl:text-lg"
                  onChange={handleChange}
                  value="2-5"
                  name="2-5"
                  checked={state["type"] === "2-5"}
                />
              }
              label="2-5"
            />
            <img
              className="absolute w-auto h-5 top-1/2 right-[20px] -translate-y-1/2 pointer-events-none"
              src={arrowRightBlack}
              width={20}
              height={20}
              alt="arrow right"
            />
          </div>

          <div
          onClick={handleFetchData}
            className={`hover:shadow hover:bg-softBlue ${
              state["type"] === "5-10"
                ? "border-softBlue bg-softBlue"
                : "border-borderGray bg-lightGray"
            } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
          >
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <IOSSwitch
                  className="xl:text-lg"
                  onChange={handleChange}
                  value="5-10"
                  name="5-10"
                  checked={state["type"] === "5-10"}
                />
              }
              label="5-10"
            />
            <img
              className="absolute w-auto h-5 top-1/2 right-[20px] -translate-y-1/2 pointer-events-none"
              src={arrowRightBlack}
              width={20}
              height={20}
              alt="arrow right"
            />
          </div>

          <div
          onClick={handleFetchData}
            className={`hover:shadow hover:bg-softBlue ${
              state["type"] === "10+"
                ? "border-softBlue bg-softBlue"
                : "border-borderGray bg-lightGray"
            } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
          >
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <IOSSwitch
                  className="xl:text-lg"
                  onChange={handleChange}
                  value="10+"
                  name="10+"
                  checked={state["type"] === "10+"}
                />
              }
              label="10+"
            />
            <img
              className="absolute w-auto h-5 top-1/2 right-[20px] -translate-y-1/2 pointer-events-none"
              src={arrowRightBlack}
              width={20}
              height={20}
              alt="arrow right"
            />
          </div>
        </RadioGroup>
      </FormControl>

      <div className="w-full flex justify-between items-center">
        <NavLink
          ref={leftArrowRef}
          to={"/survey/6"}
          className="h-12 w-12 flex items-center justify-center shrink-0 rounded-xl bg-white transition-colors hover:bg-lightBlue border border-blue"
        >
          <img
            className="-translate-x-[2px]"
            src={arrow}
            width={12}
            height={20}
            alt="arrow"
          />
        </NavLink>

        <button
          onClick={() => {
            fetchData(1, 7, () => navigate("/hiring-call"));
          }}
          className={`${
            empty ? "pointer-events-none opacity-40" : ""
          } mx-auto h-12 w-28 flex items-center justify-center shrink-0 rounded-xl font-medium text-white bg-blue transition-colors hover:bg-darkBlue cursor-pointer`}
        >
          OK
        </button>

        <button
          ref={rightArrowRef}
          onClick={() => {
            fetchData(1, 7, () => navigate("/hiring-call"));
          }}
          className={`${
            empty ? "pointer-events-none opacity-40" : ""
          } h-12 w-12 flex items-center justify-center shrink-0 rounded-xl bg-white transition-colors hover:bg-lightBlue border border-blue`}
        >
          <img
            className="translate-x-[2px] rotate-180"
            src={arrow}
            width={12}
            height={20}
            alt="arrow"
          />
        </button>
      </div>
    </div>
  );
}
