import React, {  useEffect } from "react";
import { useSelector } from "react-redux";
import PitchDeck from "../../PitchDeckAdmin/PitchDeck";

const SearchAI = () => {
  
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  return (
    <section className="settings">
      <div className="container max-w-[1058px]">
        <div className="text-block mt-3 mb-0 sm:mb-9">
          <h1 className="text-2xl font-bold text-blackPrimary">
            Search with AI
          </h1>
          <p className="text-sm font-normal text-themeGray">
            Here's the current update for today.
          </p>
        </div>

        <PitchDeck />
       
      </div>
    </section>
  );
};

export default SearchAI;
