import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setThemeState, toggleTheme } from "../../features/theme/themeSlice";

// import { useSelector } from "react-redux";

import logo from "./../../img/logo.svg";
import logoDark from "./../../img/logo-dark.svg";
import ball from "./../../img/ball.svg";

const Header = () => {
  // tmp
  localStorage.removeItem("theme");
  // end tmp
  const [theme, setTheme] = useState(sessionStorage.theme);
  const dispatch = useDispatch();
  setThemeState(true);
  useEffect(() => {
    if (sessionStorage.theme === "dark" || !("theme" in sessionStorage)) {
      document.documentElement.classList.add("dark");
      sessionStorage.theme = "dark";
      setTheme("dark");
      dispatch(setThemeState(true));
    } else {
      document.documentElement.classList.remove("dark");
      sessionStorage.theme = "light";
      setTheme("light");
      dispatch(setThemeState(false));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (sessionStorage.theme === "dark") {
      document.documentElement.classList.add("dark");
      sessionStorage.theme = "dark";
    } else {
      document.documentElement.classList.remove("dark");
      sessionStorage.theme = "light";
    }
  }, [theme]);

  const handleThemeSwitch = () => {
    sessionStorage.theme = theme === "dark" ? "light" : "dark";
    setTheme(theme === "dark" ? "light" : "dark");
    dispatch(toggleTheme());
  };

  return (
    <header className="hidden lg:flex fixed items-center h-[75px] 2xl:h-[104px] top-0 z-50 w-full bg-white dark:bg-themeBlack border-b border-b-lightBlue">
      <div className="container max-w-[1332px] mx-auto px-4">
        <div className="wrapper flex justify-between items-center">
          <NavLink className={`flex object-contain shrink-0`} to={"/"}>
            <img
              src={theme === "dark" ? logoDark : logo}
              className="logo h-[36px] md:h-[46px] w-auto"
              width={"110"}
              height={"52"}
              alt="logo"
            />
          </NavLink>

          <div className="nav flex items-center gap-3">
            <NavLink
              className="relative flex items-center py-3 px-2 lg:px-3 md:text-sm xl:text-base whitespace-nowrap hover:text-blue before:content-[''] before:bg-blue before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
              to={"/#features"}
            >
              Features
            </NavLink>
            <NavLink
              className="relative flex items-center py-3 px-2 lg:px-3 md:text-sm xl:text-base whitespace-nowrap hover:text-blue before:content-[''] before:bg-blue before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
              to={"/#services"}
            >
              Our services
            </NavLink>
            <NavLink
              className="relative flex items-center py-3 px-2 lg:px-3 md:text-sm xl:text-base whitespace-nowrap hover:text-blue before:content-[''] before:bg-blue before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
              to={"/#pricing"}
            >
              Pricing
            </NavLink>
            <NavLink
              className="relative flex items-center py-3 px-2 lg:px-3 md:text-sm xl:text-base whitespace-nowrap hover:text-blue before:content-[''] before:bg-blue before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
              to={"/#contact"}
            >
              Contact Us
            </NavLink>
            <a
              href="https://blog.talentbankai.com/"
              className="relative flex items-center py-3 px-2 lg:px-3 md:text-sm xl:text-base whitespace-nowrap hover:text-blue before:content-[''] before:bg-blue before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="talentbankai blog"
            >
              Blog
            </a>
          </div>

          <div className="flex">
            <button
              className="theme-btn w-14 h-14 mr-7 flex items-center justify-center rounded-full transition-colors"
              onClick={handleThemeSwitch}
            >
              {theme === "dark" ? (
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.2523 15.0014C21.2523 18.4536 18.4538 21.2521 15.0017 21.2521C11.5495 21.2521 8.75101 18.4536 8.75101 15.0014C8.75101 11.5493 11.5495 8.75077 15.0017 8.75077C18.4538 8.75077 21.2523 11.5493 21.2523 15.0014Z"
                    stroke="#7F8EA3"
                    strokeWidth="2.50008"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.62564 14.9977C5.62564 13.9613 4.78702 13.1227 3.75058 13.1227H1.87506C0.83862 13.1227 0 13.9613 0 14.9977C0 16.0342 0.83862 16.8728 1.87506 16.8728H3.75012C4.78702 16.8728 5.62564 16.0342 5.62564 14.9977Z"
                    fill="#7F8EA3"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.7208 21.627L4.39326 22.955C3.66058 23.6872 3.66058 24.8742 4.39326 25.6064C5.12547 26.3386 6.31238 26.3386 7.04506 25.6064L8.37307 24.2788C9.10528 23.5466 9.10528 22.3592 8.37307 21.627C7.63992 20.8943 6.45348 20.8943 5.7208 21.627Z"
                    fill="#7F8EA3"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.002 24.3739C13.9656 24.3739 13.127 25.2125 13.127 26.249V28.1245C13.127 29.1609 13.9656 29.9995 15.002 29.9995C16.0384 29.9995 16.8771 29.1609 16.8771 28.1245L16.8752 26.249C16.8771 25.2125 16.0366 24.3739 15.002 24.3739Z"
                    fill="#7F8EA3"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.2811 21.6289C23.5503 20.8948 22.3634 20.8948 21.6312 21.627C20.899 22.3593 20.899 23.5466 21.6312 24.2788L22.9573 25.6045C23.6895 26.3405 24.8764 26.3367 25.6087 25.6045C26.3409 24.8723 26.3427 23.6873 25.6087 22.9532L24.2811 21.6289Z"
                    fill="#7F8EA3"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28.1285 13.1227L26.2492 13.1264C25.2166 13.1227 24.3779 13.9613 24.3779 14.9977C24.3742 16.0342 25.2166 16.8728 26.2492 16.8728H28.1248C29.165 16.8765 29.9998 16.0342 29.9998 14.9977C30.0017 13.9632 29.165 13.1227 28.1285 13.1227Z"
                    fill="#7F8EA3"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.2811 8.37027L25.6068 7.04414C26.3446 6.31005 26.339 5.12502 25.6068 4.39234C24.876 3.65778 23.6895 3.65778 22.9573 4.39046L21.6312 5.71988C20.899 6.44881 20.899 7.63525 21.6312 8.36793C22.3596 9.10483 23.5503 9.10107 24.2811 8.37027Z"
                    fill="#7F8EA3"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.002 5.62144C16.0366 5.62894 16.8771 4.78282 16.8771 3.75013V1.87507C16.8771 0.834882 16.0384 1.25284e-05 15.002 1.25284e-05C13.9656 -0.00373759 13.127 0.834882 13.127 1.87179L13.1307 3.7506C13.127 4.78282 13.9656 5.62144 15.002 5.62144Z"
                    fill="#7F8EA3"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.72475 8.36833C6.45368 9.10054 7.63684 9.10054 8.37139 8.36646C9.10923 7.63941 9.10548 6.44921 8.37514 5.71841L7.04901 4.39274C6.31305 3.65631 5.12989 3.66194 4.39721 4.39274C3.6589 5.12355 3.6589 6.30999 4.39158 7.04267L5.72475 8.36833Z"
                    fill="#7F8EA3"
                  />
                </svg>
              ) : (
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.03 17.2291C24.9959 22.375 20.4507 26.2508 15.0004 26.2508C8.78699 26.2508 3.75004 21.2138 3.75004 15.0005C3.75004 9.55013 7.62584 5.00501 12.7717 3.97089C11.0613 5.66851 10.0002 8.02268 10.0002 10.6254C10.0002 15.8033 14.1976 20.0007 19.3755 20.0007C21.9782 20.0007 24.3324 18.9396 26.03 17.2291Z"
                    stroke="#7F8EA3"
                    strokeWidth="2.50008"
                  />
                </svg>
              )}
            </button>

            <NavLink
              to={"/survey/1"}
              className="group hover:bg-darkBlue transition-colors h-[54px] px-2 mr-7 md:w-40 lg:w-44 flex justify-center items-center rounded-xl text-base font-medium text-white bg-blue"
            >
              <p className="me-2 font-Author text-lg whitespace-nowrap">
                Get Started
              </p>
              <img
                className="group-hover:rotate-90 group-hover:translate-x-2 duration-500 h-[22px] w-[22px]"
                src={ball}
                width={22}
                height={22}
                alt="ball"
              />
            </NavLink>

            <NavLink
              // to={"/admin/login"}
              to={"/login"}
              className="hover:bg-blue hover:text-themeWhite transition-colors h-[54px] px-2 w-28 flex justify-center items-center rounded-xl text-base font-medium text-blackPrimary dark:text-themeWhite border-2 border-blue"
            >
              <p className="font-Author font-semibold text-lg whitespace-nowrap">
                Log in
              </p>
            </NavLink>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
