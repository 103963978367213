import { React, useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  setActiveTab,
  setTabsContent,
} from "./../../../features/tabs/tabsSlice";
import "./style.scss";

import logo from "./../../../img/logo.svg";
import burger from "./../../../img/burger.svg";
import cross from "./../../../img/cross.svg";

import { ReactComponent as IconDashboard } from "./../icons/dashboard.svg";
import { ReactComponent as IconSearch } from "./../icons/search.svg";
import { ReactComponent as IconStar } from "./../icons/star.svg";
import { ReactComponent as SettingsIcon } from "./../icons/settings.svg";

import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

const HeaderAdmin = () => {

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const activeTab = useSelector((state) => state.tabs.activeTab);
  // const tabsContent = useSelector((state) => state.tabs.tabsContent);

  const handleOpenMenu = (event) => {
    document.body.style = null;
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleGoMain = () => {
    setAnchorEl(null);
    handleDrawerToggle();
    navigate("/");
  };

  const handleTabChange = (index) => {
    dispatch(setActiveTab(index));
    dispatch(setTabsContent(""));
    handleDrawerToggle();
    returnBodyScroll();
  };

  const delBodyScroll = () => {
    document.body.style.overflow = "hidden";
  };
  const returnBodyScroll = () => {
    document.body.style.overflow = "auto";
  };


  function Tab(props) {
    const { label, icon, isActive, onClick } = props;

    return (
      <li
        className={`tab max-w-[210px] w-full flex items-center h-11 px-5 py-3 mb-2 rounded-lg cursor-pointer ${
          isActive ? "active" : ""
        }`}
        onClick={onClick}
      >
        <span className="tab-icon flex w-5 h-5 mr-3">{icon}</span>
        <span className="tab-label text-sm font-bold dark:text-blackPrimary">
          {label}
        </span>
      </li>
    );
  }

  function Tabs(props) {
    const { tabs, activeTab, onTabChange } = props;

    return (
      <ul className="tab-list">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            icon={tab.icon}
            isActive={index === activeTab}
            onClick={() => onTabChange(index)}
          />
        ))}
      </ul>
    );
  }

  const tabs = [
    { label: "Dashboard", icon: <IconDashboard /> },
    { label: "Search talent", icon: <IconSearch /> },
    { label: "Search with AI", icon: <IconStar /> },
  ];

  return (
    <header className="header-admin fixed lg:hidden h-[68px] w-full top-0 z-50 bg-white transition-all border-b border-b-lightBlue">
      <div className="wrap-mobile-menu w-full z-10">
        <div className="burger-menu-wrapper h-[68px] w-full z-20 px-4 flex items-center justify-between">
          <img
            onClick={(e) => {
              dispatch(setActiveTab(0));
              dispatch(setTabsContent(""));
              handleCloseMenu();
              setMobileOpen(false);
              returnBodyScroll();
            }}
            src={logo}
            className="logo h-[36px] md:h-[46px] w-auto z-20 cursor-pointer"
            width={"110"}
            height={"52"}
            alt="logo"
          />

          {!mobileOpen && (
            <div
              onClick={() => {
                handleDrawerToggle();
                delBodyScroll();
              }}
              className="burger-menu-btn cursor-pointer z-20"
            >
              <div className="flex items-center justify-center p-3 rounded-md bg-blue">
                <img
                  src={burger}
                  className="h-[18px] w-auto"
                  width={"18"}
                  height={"18"}
                  alt="burger"
                />
              </div>
            </div>
          )}
          {mobileOpen && (
            <>
              <Avatar
                sx={{
                  zIndex: 20,
                  marginLeft: "auto",
                  marginRight: "24px",
                  bgcolor: "#ffffff",
                  color: "rgba(59, 135, 255, 1)",
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "19px",
                  border: "1px solid rgba(59, 135, 255, 1)",
                  cursor: "pointer",
                }}
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleOpenMenu}
              >
                VV
              </Avatar>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                slotProps={{
                  paper: { style: { borderRadius: 16, width: 215 } },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                TransitionComponent={Fade}
                anchorOrigin={{
                  vertical: 0,
                  horizontal: 150,
                }}
              >
                <MenuItem
                  sx={{
                    "&:hover": { backgroundColor: "rgba(243, 248, 255, 1)" },
                    backgroundColor: "transparent",
                    padding: "12px 20px",
                    color: "rgba(20, 15, 27, 1)",
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: "21px",
                  }}
                  onClick={() => {
                    handleCloseMenu();
                    // dispatch(setTabsContent(false));
                    dispatch(setTabsContent("settings"));
                    handleDrawerToggle();
                  }}
                >
                  <SettingsIcon className="mr-3" />
                  Account settings
                </MenuItem>

                <MenuItem
                  sx={{
                    "&:hover": { backgroundColor: "rgba(243, 248, 255, 1)" },
                    padding: "12px 20px 12px 22px",
                    color: "rgba(20, 15, 27, 1)",
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: "21px",
                  }}
                  onClick={() => {
                    handleCloseMenu();
                    handleDrawerToggle();
                    returnBodyScroll();
                    handleGoMain();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>

              <div
                onClick={() => {
                  handleDrawerToggle();
                  returnBodyScroll();
                }}
                className="burger-menu-btn cursor-pointer z-20"
              >
                <div className="flex items-center justify-center p-3 rounded-md bg-blue">
                  <img
                    src={cross}
                    className="h-[18px] w-auto"
                    width={"18"}
                    height={"18"}
                    alt="cross"
                  />
                </div>
              </div>
            </>
          )}
          {mobileOpen && (
            <div className="wrap-mobile-list-menu fixed top-0 left-0 pt-24 w-full h-screen bg-white z-10 overflow-y-scroll">
              <div className="mobile-list-menu mb-24 sm:w-[210px] sm:mx-auto">
                <nav className="nav px-4 sm:px-0">
                  <Tabs
                    tabs={tabs}
                    activeTab={activeTab}
                    onTabChange={handleTabChange}
                  />
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderAdmin;
