import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import fullTime from "./../../../../img/Survey/icons/full-time.svg";
import arrow from "./../../../../img/Survey/icons/arrow-right.svg";
import partTime from "./../../../../img/Survey//icons/part-time.svg";
import "./style.scss";

export default function Step1() {
  let storage = JSON.parse(sessionStorage.getItem("1"));

  const navigate = useNavigate();
  const [state, setState] = React.useState(
    storage || {
      type: null,
    }
  );

  useEffect(() => {
    if (sessionStorage.getItem("uuid") === null) {
      sessionStorage.setItem("uuid", "");
    }
    if (sessionStorage.getItem("step2data") === null) {
      sessionStorage.setItem("step2data", "");
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("1", JSON.stringify(state));
  }, [state]);

  const handleChange = (event) => {
    setState({
      type: event.target.value,
    });
  };

  return (
    <div className="step-1 flex w-full max-w-[350px] flex-col items-center gap-8">
      <FormControl>
        <RadioGroup
          className="w-full flex justify-center items-center flex-wrap gap-2"
          aria-labelledby="radio-buttons-group"
          name="radio-buttons-group"
          value={state["type"]}
          onChange={handleChange}
        >
          <div
            onClick={() =>
              setTimeout(() => {
                navigate("/survey/2");
              }, 10)
            }
            className={`hover:shadow hover:bg-softBlue ${
              state["type"] === "fulltime"
                ? "border-softBlue bg-softBlue"
                : "border-borderGray bg-lightGray"
            } relative h-[56px] w-full flex items-center rounded-lg border transition-colors`}
          >
            <img
              className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
              src={fullTime}
              width={24}
              height={24}
              alt="Full time"
            />
            <FormControlLabel
              value="fulltime"
              name="fulltime"
              control={<Radio />}
              label="Full time"
            />
            <img
              className="absolute w-auto h-5 top-1/2 right-[20px] -translate-y-1/2 pointer-events-none"
              src={arrow}
              width={20}
              height={20}
              alt="arrow right"
            />
          </div>

          <div
            onClick={() =>
              setTimeout(() => {
                navigate("/survey/2");
              }, 10)
            }
            className={`hover:shadow hover:bg-softBlue ${
              state["type"] === "parttime"
                ? "border-softBlue bg-softBlue"
                : "border-borderGray bg-lightGray"
            } relative h-[56px] w-full flex items-center rounded-lg border transition-colors`}
          >
            <img
              className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
              src={partTime}
              width={24}
              height={24}
              alt="Part time"
            />

            <FormControlLabel
              value="parttime"
              name="parttime"
              control={<Radio />}
              label="Part time"
            />

            <img
              className="absolute w-auto h-5 top-1/2 right-[20px] -translate-y-1/2 pointer-events-none"
              src={arrow}
              width={20}
              height={20}
              alt="arrow right"
            />
          </div>
        </RadioGroup>
      </FormControl>      
    </div>
  );
}
