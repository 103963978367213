import React from "react";

const StepImg = ({ num }) => {
  return (
    <img
      className="w-auto h-[60px] mb-5"
      src={require(`./../../../img/Survey/icons/step-${num}-icon.png`)}
      width={60}
      height={60}
      alt="React"
    />
  );
};

export default StepImg;
