import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "./styles.css";
import adam from "./../../img/adam.webp";
import gabriel from "./../../img/gabriel.webp";
import tracy from "./../../img/tracy.jpg";
import han from "./../../img/han.jpg";

export default function SplideSlider() {
  return (
    <Splide
      options={{
        mediaQuery: "min",
        perPage: 1,
        width: 320,
        gap: "0",
        arrows: false,
      }}
    >
      <SplideSlide>
        <div className="card flex flex-col p-7 pt-6 w-[320px] rounded-2xl bg-blue text-white border border-border">
          <div className="w-[70px] h-[70px] mb-5 overflow-hidden rounded-full shrink-0">
            <img
              className="w-[70px] h-[70px] object-cover"
              src={han}
              width={70}
              height={70}
              alt="han"
            />
          </div>
          <p className="dark:text-themeBlackText">
            We have worked with DevBrother for almost a year on several
            different projects. The team is responsive, caters to our needs, and
            communicates extremely well. Their skill-level and commitment to
            project success is commendable and their rates are competitive.
            Overall, the services DevBrother offers is what we hoped for in an
            ideal development partner.
          </p>
          <p className="mt-auto text-3xl font-semibold font-Author whitespace-nowrap dark:text-themeBlackText">
            Han Kao
          </p>
          <p className="text-lg">CEO / Founder, Crypto Briefing</p>
        </div>
      </SplideSlide>

      <SplideSlide>
        <div className="card flex flex-col p-7 pt-6 w-[320px] rounded-2xl bg-white dark:bg-themeBlackText border border-border">
          <div className="w-[70px] h-[70px] mb-5 overflow-hidden rounded-full shrink-0">
            <img
              className="w-[70px] h-[70px] object-cover"
              src={tracy}
              width={70}
              height={70}
              alt="tracy"
            />
          </div>
          <p>
            DevBrother successfully implemented new features, improved the
            existing features' UI/UX design, and delivered high-quality game art
            components. Their highly skilled experts met all the planned
            milestones on time. They were also responsive, collaborative, and
            communicative via email and meetings.
          </p>
          <p className="mt-auto text-3xl font-Author font-semibold">
            Tracy Chang
          </p>
          <p className="text-lg dark:text-themeGray">CEO, Tradelite Solutions</p>
        </div>
      </SplideSlide>

      <SplideSlide>
        <div className="card flex flex-col p-7 pt-6 w-[320px] rounded-2xl bg-blue text-white border border-border">
          <div className="w-[70px] h-[70px] mb-5 overflow-hidden rounded-full shrink-0">
            <img
              className="w-[70px] h-[70px] object-cover"
              src={gabriel}
              width={70}
              height={70}
              alt="gabriel"
            />
          </div>
          <p className="dark:text-themeBlackText">
            The team was very flexible in working with the demands of a niche
            company. Their expertise also shone through as they pushed back on
            some of the requests that they knew would deter the progress of the
            project. The company appreciated their honesty as a huge asset.
          </p>
          <p className="mt-auto text-3xl font-Author font-semibold dark:text-themeBlackText">
            Gabriel Afana
          </p>
          <p className="text-lg">CEO, Ticketing Platform</p>
        </div>
      </SplideSlide>

      <SplideSlide>
        <div className="card flex flex-col p-7 pt-6 w-[320px] rounded-2xl bg-white dark:bg-themeBlackText border border-border">
          <div className="w-[70px] h-[70px] mb-5 overflow-hidden rounded-full shrink-0">
            <img
              className="w-[70px] h-[70px] object-cover"
              src={adam}
              width={70}
              height={70}
              alt="adam"
            />
          </div>
          <p>
            DevBrother's (TalentBank) strong development skills allow them to
            provide incredible solutions to the company, which enable them to
            meet expectations. The client highly appreciates their transparent
            and understanding approach. Above all, they provide precise
            estimates, and they work in a timely manner.
          </p>
          <p className="mt-auto text-3xl font-Author font-semibold">
            Adam Szajcz
          </p>
          <p className="text-lg dark:text-themeGray">CEO, FAT moves UG</p>
        </div>
      </SplideSlide>
    </Splide>
  );
}
