import React from "react";
import { NavLink } from "react-router-dom";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { InlineWidget } from "react-calendly";
import "./style.scss"
import downArrow from "./../../img/down-arrow.svg" 

export default function HiringCall() {

  let uuid = sessionStorage.getItem("uuid");
  let url_1 = `https://talentbankai.com/cvs/preview/${uuid}_0.png`;
  let url_2 = `https://talentbankai.com/cvs/preview/${uuid}_1.png`;

  return (
    <div className="call container max-w-[1332px] mx-auto px-4 relative z-10 pt-28 sm:pt-32 md:pb-20 lg:pt-36 2xl:pt-48">
      <div className="wrapper flex flex-col justify-center items-center text-center">
        
        <h1 className="mb-4 max-w-[450px] 2xl:max-w-[570px] capitalize text-5xl 2xl:text-7xl font-semibold font-Author">We've found candidates for you</h1>
        
        <NavLink to={"#calendly"} className="group hover:bg-darkBlue transition-colors h-[46px] px-2 mb-10 w-full sm:w-48 flex justify-center items-center rounded-xl text-base font-medium text-white bg-blue">
            <p className="ms-2 me-4 font-Author text-lg whitespace-nowrap">Book Hiring Call</p>
            <img
              className="rotate-90 group-hover:translate-y-1 duration-500 h-[22px] w-[22px]"
              src={downArrow}
              width={22}
              height={22}
              alt="move to calendly"
            />
        </NavLink>

        <div className="images-wrapper flex flex-nowrap mb-10 gap-6 sm:gap-8">
          <div className="wrap relative mx-auto">
              <Zoom>
            <img className="border border-lightBlue rounded-lg"
              src={url_1} width={200} height={260} alt="candidat" />
              </Zoom>
         </div>

         <div className="wrap relative mx-auto">
              <Zoom>
            <img className="border border-lightBlue rounded-lg"
              src={url_2} width={200} height={260} alt="candidat" />
              </Zoom>
          </div>
        </div>

        <p className="max-w-[420px] mb-4 sm:mb-0 text-xl font-semibold text-blackPrimary09 dark:text-white">
        Book a call to receive more tailored candidate match from our AI
        </p>

        <div id="calendly" className="w-full mb-10 lg:-mt-7">          
        {/* <InlineWidget url="https://calendly.com/vlad522/30min?hide_event_type_details=1&hide_gdpr_banner=1"/> */}
        <InlineWidget url="https://calendly.com/yuriy-golikov/30min?hide_event_type_details=1&hide_gdpr_banner=1"/>
        </div>

      </div>
    </div>
  );
}
