import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import {
  setActiveTab,
  setTabsContent,
} from "./../../../features/tabs/tabsSlice";
import { PopupButton } from "react-calendly";

import technology from "./../icons/password.svg";
import map from "./../icons/map.svg";
import foto1 from "./../img/foto-1.png";
import foto2 from "./../img/foto-2.png";
import foto3 from "./../img/foto-3.png";
import flag from "./../icons/flag-Ukraine.svg";
import javascript from "./../icons/javascript.svg";
import NodeJS from "./../icons/NodeJS.svg";
import TypeScript from "./../icons/typescript.svg";
import NET from "./../icons/NET.svg";
import ReactJS from "./../icons/React.svg";
import ReactNative from "./../icons/Reactnative.svg";
import Languages from "./../icons/languages.svg";
import Experience from "./../icons/user.svg";
import Education from "./../icons/education.svg";

const Profile = () => {

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  const dispatch = useDispatch();

  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);

  const handleClick1 = () => {
    setIsActive1(!isActive1);
  };

  const handleClick2 = () => {
    setIsActive2(!isActive2);
  };

  const handleClick3 = () => {
    setIsActive3(!isActive3);
  };

  const handleTabChange = (index, page) => {
    dispatch(setActiveTab(index));
    dispatch(setTabsContent(page));
  };

  return (
    <section className="profile">
      <div className="container max-w-[1058px]">
        <div className="text-block mt-3 mb-7 md:mb-10">
          <a
            className="back-btn hover:text-blue transition-all flex items-center text-base font-medium text-grey cursor-pointer"
            onClick={() => handleTabChange(1)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 2L6.70711 9.29289C6.31658 9.68342 6.31658 10.3166 6.70711 10.7071L14 18"
                stroke="#4B5865"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <p className="ml-2">Back to talents</p>
          </a>
        </div>

        <div className="content-section">
          <div className="persons-item relative mb-5 w-full p-4 md:p-6 rounded-2xl bg-white">
            <a
              onClick={() => {
                dispatch(setTabsContent("profile"));
              }}
              className="hidden absolute z-10 top-0 right-0 bottom-0 left-0 cursor-pointer"
            ></a>
            <div className="persons-item__header mb-4 flex items-start justify-between flex-wrap gap-4">
              <div className="flex items-center gap-4 sm:gap-7">
                <div className="wrapper relative">
                  <div className="w-[60px] h-[60px] overflow-hidden rounded-full shrink-0">
                    <img
                      className="w-[60px] h-[60px] object-cover"
                      src={foto1}
                      width={60}
                      height={60}
                      alt="candidat"
                    />
                    <img
                      className="absolute left-1/2 -bottom-1 -translate-x-1/2 w-[18px] h-[14px] object-cover"
                      src={flag}
                      width={18}
                      height={14}
                      alt="flag"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-1 w-min md:w-auto">
                  <p className="text-base text-blackPrimary font-bold">
                    Anton H.
                  </p>
                  <div className="flex flex-wrap gap-x-4 gap-y-1">
                    <div className="flex items-center">
                      <img
                        src={technology}
                        className="mr-2"
                        width={20}
                        height={20}
                        alt="technology"
                      />
                      <p className="whitespace-nowrap">NodeJS Developer</p>
                    </div>
                    <div className="flex items-center">
                      <img
                        src={map}
                        className="mr-2"
                        width={20}
                        height={20}
                        alt="map"
                      />
                      <p className="whitespace-nowrap">Ukraine</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btns-wrap flex items-center gap-5">
                {/* <button className="group z-20 hover:bg-darkBlue transition-colors h-10 px-2 w-[190px] flex justify-center items-center rounded-lg text-lg font-Author font-semibold text-white bg-blue whitespace-nowrap">
                  <p className="whitespace-nowrap">Request Interview</p>
                </button> */}
                <PopupButton
                  url="https://calendly.com/yuriy-golikov/30min?hide_event_type_details=1&hide_gdpr_banner=1"
                  rootElement={document.getElementById("root")}
                  text="Request Interview"
                  className="group z-20 hover:bg-darkBlue transition-colors h-10 px-2 w-[190px] flex justify-center items-center rounded-lg text-lg font-Author font-semibold text-white bg-blue"
                />
                <div
                  onClick={handleClick1}
                  className={`${
                    isActive1 ? "active" : ""
                  } like-btn relative z-20 w-10 h-10 flex items-center justify-center shrink-0 rounded-full border border-themeGray07 hover:border-blue transition-colors cursor-pointer`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.371 3.47874C9.56391 2.92381 9.66033 2.64635 9.803 2.56945C9.92641 2.50289 10.075 2.50289 10.1985 2.56945C10.3411 2.64635 10.4375 2.92381 10.6304 3.47874L11.9057 7.1474C11.9606 7.30534 11.988 7.38431 12.0375 7.44312C12.0812 7.49507 12.1369 7.53556 12.1998 7.56106C12.271 7.58993 12.3546 7.59163 12.5217 7.59504L16.4049 7.67417C16.9923 7.68614 17.286 7.69213 17.4032 7.80401C17.5047 7.90083 17.5506 8.04219 17.5254 8.18015C17.4964 8.33957 17.2623 8.51699 16.7942 8.87199L13.6991 11.2184C13.5658 11.3195 13.4992 11.37 13.4586 11.4352C13.4227 11.4928 13.4014 11.5583 13.3966 11.626C13.3912 11.7027 13.4153 11.7827 13.4637 11.9427L14.5885 15.6603C14.7586 16.2227 14.8437 16.5038 14.7735 16.6498C14.7127 16.7762 14.5925 16.8636 14.4535 16.8823C14.2929 16.9039 14.0518 16.7362 13.5696 16.4006L10.3815 14.1822C10.2442 14.0867 10.1757 14.0389 10.1011 14.0203C10.0352 14.004 9.96625 14.004 9.90041 14.0203C9.82583 14.0389 9.75716 14.0867 9.61991 14.1822L6.43187 16.4006C5.94964 16.7362 5.70852 16.9039 5.54794 16.8823C5.40896 16.8636 5.28871 16.7762 5.22797 16.6498C5.15779 16.5038 5.24285 16.2227 5.41298 15.6603L6.53769 11.9427C6.5861 11.7827 6.61031 11.7027 6.60486 11.626C6.60005 11.5583 6.57876 11.4928 6.54285 11.4352C6.50221 11.37 6.43559 11.3195 6.30235 11.2184L3.20731 8.87199C2.73915 8.51699 2.50506 8.33957 2.476 8.18015C2.45085 8.04219 2.49678 7.90083 2.59822 7.80401C2.71542 7.69213 3.00911 7.68615 3.59649 7.67417L7.47966 7.59504C7.64683 7.59163 7.73042 7.58993 7.80164 7.56106C7.86455 7.53556 7.92028 7.49507 7.96397 7.44312C8.01344 7.38431 8.04089 7.30534 8.09579 7.1474L9.371 3.47874Z"
                      stroke="#4B5865"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="persons-item__footer flex gap-2 items-center justify-between flex-wrap">
              <div className="tech-list-block px-4 py-2 rounded-lg flex items-center justify-between flex-wrap bg-blue012">
                <ul className="tech-list flex items-center gap-3 flex-wrap">
                  <li className="h-9 px-3 flex justify-center items-center border-[2px] border-lightBlue rounded-full text-sm text-blackPrimary bg-white">
                    <img
                      className="w-[15px] h-[15px] mr-2 object-cover"
                      src={NodeJS}
                      width={15}
                      height={15}
                      alt="NodeJS"
                    />
                    <p className="whitespace-nowrap">NodeJS</p>
                  </li>

                  <li className="h-9 px-3 flex justify-center items-center border-[2px] border-lightBlue rounded-full text-sm text-blackPrimary bg-white">
                    <img
                      className="w-[15px] h-[15px] mr-2 object-cover"
                      src={javascript}
                      width={15}
                      height={15}
                      alt="JavaScript"
                    />
                    <p className="whitespace-nowrap">JavaScript</p>
                  </li>

                  <li className="h-9 px-3 flex justify-center items-center border-[2px] border-lightBlue rounded-full text-sm text-blackPrimary bg-white">
                    <img
                      className="w-[15px] h-[15px] mr-2 object-cover"
                      src={TypeScript}
                      width={15}
                      height={15}
                      alt="TypeScript"
                    />
                    <p className="whitespace-nowrap">TypeScript</p>
                  </li>

                  <li className="h-9 px-3 flex justify-center items-center border-[2px] border-lightBlue rounded-full text-sm text-blackPrimary bg-white">
                    <img
                      className="w-[15px] h-[15px] mr-2 object-cover"
                      src={NET}
                      width={15}
                      height={15}
                      alt=".NET"
                    />
                    <p className="whitespace-nowrap">.NET</p>
                  </li>
                </ul>
              </div>
              <div className="price text-base font-bold text-blackPrimary ml-auto">
                <span>$3,979</span>/month
              </div>
            </div>
          </div>

          <div className="info flex flex-wrap lg:flex-nowrap gap-5">
            <div className="summary flex flex-col flex-wrap w-full p-4 md:p-6 rounded-2xl text-base text-blackPrimary bg-white lg:flex-nowrap">
              <div className="w-full mb-5 p-4 rounded-xl flex items-center justify-between flex-wrap bg-blue012">
                <h1 className="mb-2 text-lg font-bold">Summary</h1>
                <p>
                  Anton is a responsible and fast-learning NodeJS Developer with
                  a big passion about Computer Sciences, Innovations,
                  Technologies, and Cryptocurrency. His qualification embraces
                  strong knowledge of JavaScript, NodeJS, NestJS, TypeScript,
                  HTML, CSS, and Bootstrap.
                </p>
              </div>

              <h2 className="mb-4 text-lg font-bold">Projects</h2>

              <div className="project-item mb-3">
                <h3 className="mb-2 font-semibold text-blue">Dronni</h3>
                <p className="mb-2">
                  <strong>Description:</strong> System with web and mobile
                  applications developed the API as a Back-End, Front-End, and
                  mobile part of a project. API allows you to rent free drones
                  if you are a user and allows you to list your own drones for
                  rent if you are a drone owner. The main functions of the
                  administrator are also implemented.
                </p>
                <p className="mb-2">
                  <strong>Responsibilities: </strong>Developing of Vision&Scope
                  for the project; Organizing Database structure; Building
                  client and server side; Developing the new features;
                  Responsive Mark-Up.
                </p>
                <p className="mb-2">
                  <strong>Technologies:</strong>
                  NodeJS, C#, ASP.NET, Entity, JWT, Microsoft SQL Server,
                  Vue.js, Java(Mobile).
                </p>
              </div>

              <div className="project-item mb-3">
                <h3 className="mb-2 font-semibold text-blue">RustyG</h3>
                <p className="mb-2">
                  <strong>Description:</strong> System with web and mobile
                  applications developed the API as a Back-End, Front-End, and
                  mobile part of a project. API allows you to rent free drones
                  if you are a user and allows you to list your own drones for
                  rent if you are a drone owner. The main functions of the
                  administrator are also implemented.
                </p>
                <p className="mb-2">
                  <strong>Responsibilities: </strong>Developing of Vision&Scope
                  for the project; Organizing Database structure; Building
                  client and server side; Developing the new features;
                  Responsive Mark-Up.
                </p>
                <p className="mb-2">
                  <strong>Technologies:</strong>
                  NodeJS, C#, ASP.NET, Entity, JWT, Microsoft SQL Server,
                  Vue.js, Java(Mobile).
                </p>
              </div>
            </div>

            <div className="skills flex flex-col flex-wrap w-full p-4 md:p-6 rounded-2xl text-sm text-grey bg-white lg:w-[340px] lg:min-w-[340px] lg:flex-nowrap">
              <div className="wrapper mb-4">
                <div className="w-full mb-3 p-2 rounded-xl flex items-center flex-wrap bg-blue012">
                  <img
                    src={technology}
                    className="mr-3"
                    width={24}
                    height={24}
                    alt="skills"
                  />
                  <p className="font-semibold">Skills</p>
                </div>
                <span>Technologies:</span>
                <p className="mb-3 text-base text-blackPrimary">
                  NodeJS, NestJS, Bootstrap, VueJS, .Net, React Native
                </p>
                <span>Source Controls:</span>
                <p className="mb-3 text-base text-blackPrimary">Git, GitHub</p>
                <span>Databases:</span>
                <p className="mb-3 text-base text-blackPrimary">
                  MS SQL Server, MySQL, MongoDB
                </p>
                <span>Methodology:</span>
                <p className="mb-3 text-base text-blackPrimary">
                  Agile (SCRUM)
                </p>
                <span>Other:</span>
                <p className="mb-3 text-base text-blackPrimary">
                  OOP, SOLID, KISS, DRY, TypeORM, mongoose
                </p>
              </div>

              <div className="wrapper mb-4">
                <div className="w-full mb-3 p-2 rounded-xl flex items-center flex-wrap bg-blue012">
                  <img
                    src={Languages}
                    className="mr-3"
                    width={24}
                    height={24}
                    alt="Languages"
                  />
                  <p className="font-semibold">Languages</p>
                </div>
                <p className="mb-2 text-base font-semibold">
                  English - <span className="font-normal">Intermediate</span>
                </p>
                <p className="mb-2 text-base font-semibold">
                  Ukrainian - <span className="font-normal">native</span>
                </p>
                <p className="mb-2 text-base font-semibold">
                  Russian - <span className="font-normal">fluent</span>
                </p>
              </div>

              <div className="wrapper mb-4">
                <div className="w-full mb-3 p-2 rounded-xl flex items-center flex-wrap bg-blue012">
                  <img
                    src={Experience}
                    className="mr-3"
                    width={24}
                    height={24}
                    alt="Experience"
                  />
                  <p className="font-semibold">Experience</p>
                </div>
                <p className="mb-2 text-base text-blackPrimary font-semibold">
                  NodeJS Developer
                </p>
                <p className="mb-1">November 2021 - present</p>
                <p>DevBrother</p>
              </div>

              <div className="wrapper mb-4">
                <div className="w-full mb-3 p-2 rounded-xl flex items-center flex-wrap bg-blue012">
                  <img
                    src={Education}
                    className="mr-3"
                    width={24}
                    height={24}
                    alt="Education"
                  />
                  <p className="font-semibold">Education</p>
                </div>

                <time className="block mb-2">2019 - 2023</time>
                <p className="mb-2 text-base text-blackPrimary font-semibold">
                  Kharkiv National University of Radioelectronics
                </p>
                <p>
                  Bachelor Faculty of Computer Science, Department of Software
                  Engineering
                </p>
              </div>
            </div>
          </div>
        </div>

        <h2 className="mb-10 mt-10 text-2xl font-bold text-black">
          Related candidates
        </h2>

        <ul className="persons-list flex flex-col gap-4">
          <li className="persons-item relative w-full p-4 md:p-6 rounded-2xl bg-white">
            <a
              onClick={() => {
                dispatch(setTabsContent("profile"));
              }}
              className="absolute z-10 top-0 right-0 bottom-0 left-0 cursor-pointer"
            ></a>
            <div className="persons-item__header mb-4 flex items-start justify-between flex-wrap gap-4">
              <div className="flex items-center gap-4 sm:gap-7">
                <div className="wrapper relative">
                  <div className="w-[60px] h-[60px] overflow-hidden rounded-full shrink-0">
                    <img
                      className="w-[60px] h-[60px] object-cover"
                      src={foto2}
                      width={60}
                      height={60}
                      alt="candidat"
                    />
                    <img
                      className="absolute left-1/2 -bottom-1 -translate-x-1/2 w-[18px] h-[14px] object-cover"
                      src={flag}
                      width={18}
                      height={14}
                      alt="flag"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-1 w-min md:w-auto">
                  <p className="text-base text-blackPrimary font-bold">
                    Yulia P.
                  </p>
                  <div className="flex flex-wrap gap-x-4 gap-y-1">
                    <div className="flex items-center">
                      <img
                        src={technology}
                        className="mr-2"
                        width={20}
                        height={20}
                        alt="technology"
                      />
                      <p className="whitespace-nowrap">ReactNative Developer</p>
                    </div>
                    <div className="flex items-center">
                      <img
                        src={map}
                        className="mr-2"
                        width={20}
                        height={20}
                        alt="map"
                      />
                      <p className="whitespace-nowrap">Ukraine</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btns-wrap flex items-center gap-5">
                {/* <button className="group z-20 hover:bg-darkBlue transition-colors h-10 px-2 w-[190px] flex justify-center items-center rounded-lg text-lg font-Author font-semibold text-white bg-blue">
                  <p className="whitespace-nowrap">Request Interview</p>
                </button> */}
                <PopupButton
                url="https://calendly.com/yuriy-golikov/30min?hide_event_type_details=1&hide_gdpr_banner=1"
                rootElement={document.getElementById("root")}
                text="Request Interview"
                className="group z-20 hover:bg-darkBlue transition-colors h-10 px-2 w-[190px] flex justify-center items-center rounded-lg text-lg font-Author font-semibold text-white bg-blue"
              />
                <div
                  onClick={handleClick2}
                  className={`${
                    isActive2 ? "active" : ""
                  } like-btn relative z-20 w-10 h-10 flex items-center justify-center shrink-0 rounded-full border border-themeGray07 hover:border-blue transition-colors cursor-pointer`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.371 3.47874C9.56391 2.92381 9.66033 2.64635 9.803 2.56945C9.92641 2.50289 10.075 2.50289 10.1985 2.56945C10.3411 2.64635 10.4375 2.92381 10.6304 3.47874L11.9057 7.1474C11.9606 7.30534 11.988 7.38431 12.0375 7.44312C12.0812 7.49507 12.1369 7.53556 12.1998 7.56106C12.271 7.58993 12.3546 7.59163 12.5217 7.59504L16.4049 7.67417C16.9923 7.68614 17.286 7.69213 17.4032 7.80401C17.5047 7.90083 17.5506 8.04219 17.5254 8.18015C17.4964 8.33957 17.2623 8.51699 16.7942 8.87199L13.6991 11.2184C13.5658 11.3195 13.4992 11.37 13.4586 11.4352C13.4227 11.4928 13.4014 11.5583 13.3966 11.626C13.3912 11.7027 13.4153 11.7827 13.4637 11.9427L14.5885 15.6603C14.7586 16.2227 14.8437 16.5038 14.7735 16.6498C14.7127 16.7762 14.5925 16.8636 14.4535 16.8823C14.2929 16.9039 14.0518 16.7362 13.5696 16.4006L10.3815 14.1822C10.2442 14.0867 10.1757 14.0389 10.1011 14.0203C10.0352 14.004 9.96625 14.004 9.90041 14.0203C9.82583 14.0389 9.75716 14.0867 9.61991 14.1822L6.43187 16.4006C5.94964 16.7362 5.70852 16.9039 5.54794 16.8823C5.40896 16.8636 5.28871 16.7762 5.22797 16.6498C5.15779 16.5038 5.24285 16.2227 5.41298 15.6603L6.53769 11.9427C6.5861 11.7827 6.61031 11.7027 6.60486 11.626C6.60005 11.5583 6.57876 11.4928 6.54285 11.4352C6.50221 11.37 6.43559 11.3195 6.30235 11.2184L3.20731 8.87199C2.73915 8.51699 2.50506 8.33957 2.476 8.18015C2.45085 8.04219 2.49678 7.90083 2.59822 7.80401C2.71542 7.69213 3.00911 7.68615 3.59649 7.67417L7.47966 7.59504C7.64683 7.59163 7.73042 7.58993 7.80164 7.56106C7.86455 7.53556 7.92028 7.49507 7.96397 7.44312C8.01344 7.38431 8.04089 7.30534 8.09579 7.1474L9.371 3.47874Z"
                      stroke="#4B5865"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="persons-item__footer flex gap-2 items-center justify-between flex-wrap">
              <div className="tech-list-block px-4 py-2 rounded-lg flex items-center justify-between flex-wrap bg-blue012">
                <ul className="tech-list flex items-center gap-3 flex-wrap">
                  <li className="h-9 px-3 flex justify-center items-center border-[2px] border-lightBlue rounded-full text-sm text-blackPrimary bg-white">
                    <img
                      className="w-[15px] h-[15px] mr-2 object-cover"
                      src={ReactNative}
                      width={15}
                      height={15}
                      alt="ReactNative"
                    />
                    <p className="whitespace-nowrap">ReactNative</p>
                  </li>
                  <li className="h-9 px-3 flex justify-center items-center border-[2px] border-lightBlue rounded-full text-sm text-blackPrimary bg-white">
                    <img
                      className="w-[15px] h-[15px] mr-2 object-cover"
                      src={ReactJS}
                      width={15}
                      height={15}
                      alt="ReactJS"
                    />
                    <p className="whitespace-nowrap">ReactJS</p>
                  </li>

                  <li className="h-9 px-3 flex justify-center items-center border-[2px] border-lightBlue rounded-full text-sm text-blackPrimary bg-white">
                    <img
                      className="w-[15px] h-[15px] mr-2 object-cover"
                      src={javascript}
                      width={15}
                      height={15}
                      alt="JavaScript"
                    />
                    <p className="whitespace-nowrap">JavaScript</p>
                  </li>

                  <li className="h-9 px-3 flex justify-center items-center border-[2px] border-lightBlue rounded-full text-sm text-blackPrimary bg-white">
                    <img
                      className="w-[15px] h-[15px] mr-2 object-cover"
                      src={TypeScript}
                      width={15}
                      height={15}
                      alt="TypeScript"
                    />
                    <p className="whitespace-nowrap">TypeScript</p>
                  </li>
                </ul>
              </div>
              <div className="price text-base font-bold text-blackPrimary ml-auto">
                <span>$4,100</span>/month
              </div>
            </div>
          </li>

          <li className="persons-item relative w-full p-4 md:p-6 rounded-2xl bg-white">
            <a
              onClick={() => {
                dispatch(setTabsContent("profile"));
              }}
              className="absolute z-10 top-0 right-0 bottom-0 left-0 cursor-pointer"
            ></a>
            <div className="persons-item__header mb-4 flex items-start justify-between flex-wrap gap-4">
              <div className="flex items-center gap-4 sm:gap-7">
                <div className="wrapper relative">
                  <div className="w-[60px] h-[60px] overflow-hidden rounded-full shrink-0">
                    <img
                      className="w-[60px] h-[60px] object-cover"
                      src={foto3}
                      width={60}
                      height={60}
                      alt="candidat"
                    />
                    <img
                      className="absolute left-1/2 -bottom-1 -translate-x-1/2 w-[18px] h-[14px] object-cover"
                      src={flag}
                      width={18}
                      height={14}
                      alt="flag"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-1 w-min md:w-auto">
                  <p className="text-base text-blackPrimary font-bold">
                    Yuriy G.
                  </p>
                  <div className="flex flex-wrap gap-x-4 gap-y-1">
                    <div className="flex items-center">
                      <img
                        src={technology}
                        className="mr-2"
                        width={20}
                        height={20}
                        alt="technology"
                      />
                      <p className="whitespace-nowrap">ReactJS Developer</p>
                    </div>
                    <div className="flex items-center">
                      <img
                        src={map}
                        className="mr-2"
                        width={20}
                        height={20}
                        alt="map"
                      />
                      <p className="whitespace-nowrap">Ukraine</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btns-wrap flex items-center gap-5">
                
                {/* <button className="group z-20 hover:bg-darkBlue transition-colors h-10 px-2 w-[190px] flex justify-center items-center rounded-lg text-lg font-Author font-semibold text-white bg-blue">
                  <p className="whitespace-nowrap">Request Interview</p>
                </button> */}
                <PopupButton
                url="https://calendly.com/yuriy-golikov/30min?hide_event_type_details=1&hide_gdpr_banner=1"
                rootElement={document.getElementById("root")}
                text="Request Interview"
                className="group z-20 hover:bg-darkBlue transition-colors h-10 px-2 w-[190px] flex justify-center items-center rounded-lg text-lg font-Author font-semibold text-white bg-blue"
              />
                <div
                  onClick={handleClick3}
                  className={`${
                    isActive3 ? "active" : ""
                  } like-btn relative z-20 w-10 h-10 flex items-center justify-center shrink-0 rounded-full border border-themeGray07 hover:border-blue transition-colors cursor-pointer`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.371 3.47874C9.56391 2.92381 9.66033 2.64635 9.803 2.56945C9.92641 2.50289 10.075 2.50289 10.1985 2.56945C10.3411 2.64635 10.4375 2.92381 10.6304 3.47874L11.9057 7.1474C11.9606 7.30534 11.988 7.38431 12.0375 7.44312C12.0812 7.49507 12.1369 7.53556 12.1998 7.56106C12.271 7.58993 12.3546 7.59163 12.5217 7.59504L16.4049 7.67417C16.9923 7.68614 17.286 7.69213 17.4032 7.80401C17.5047 7.90083 17.5506 8.04219 17.5254 8.18015C17.4964 8.33957 17.2623 8.51699 16.7942 8.87199L13.6991 11.2184C13.5658 11.3195 13.4992 11.37 13.4586 11.4352C13.4227 11.4928 13.4014 11.5583 13.3966 11.626C13.3912 11.7027 13.4153 11.7827 13.4637 11.9427L14.5885 15.6603C14.7586 16.2227 14.8437 16.5038 14.7735 16.6498C14.7127 16.7762 14.5925 16.8636 14.4535 16.8823C14.2929 16.9039 14.0518 16.7362 13.5696 16.4006L10.3815 14.1822C10.2442 14.0867 10.1757 14.0389 10.1011 14.0203C10.0352 14.004 9.96625 14.004 9.90041 14.0203C9.82583 14.0389 9.75716 14.0867 9.61991 14.1822L6.43187 16.4006C5.94964 16.7362 5.70852 16.9039 5.54794 16.8823C5.40896 16.8636 5.28871 16.7762 5.22797 16.6498C5.15779 16.5038 5.24285 16.2227 5.41298 15.6603L6.53769 11.9427C6.5861 11.7827 6.61031 11.7027 6.60486 11.626C6.60005 11.5583 6.57876 11.4928 6.54285 11.4352C6.50221 11.37 6.43559 11.3195 6.30235 11.2184L3.20731 8.87199C2.73915 8.51699 2.50506 8.33957 2.476 8.18015C2.45085 8.04219 2.49678 7.90083 2.59822 7.80401C2.71542 7.69213 3.00911 7.68615 3.59649 7.67417L7.47966 7.59504C7.64683 7.59163 7.73042 7.58993 7.80164 7.56106C7.86455 7.53556 7.92028 7.49507 7.96397 7.44312C8.01344 7.38431 8.04089 7.30534 8.09579 7.1474L9.371 3.47874Z"
                      stroke="#4B5865"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="persons-item__footer flex gap-2 items-center justify-between flex-wrap">
              <div className="tech-list-block px-4 py-2 rounded-lg flex items-center justify-between flex-wrap bg-blue012">
                <ul className="tech-list flex items-center gap-3 flex-wrap">
                  <li className="h-9 px-3 flex justify-center items-center border-[2px] border-lightBlue rounded-full text-sm text-blackPrimary bg-white">
                    <img
                      className="w-[15px] h-[15px] mr-2 object-cover"
                      src={ReactJS}
                      width={15}
                      height={15}
                      alt="ReactJS"
                    />
                    <p className="whitespace-nowrap">ReactJS</p>
                  </li>

                  <li className="h-9 px-3 flex justify-center items-center border-[2px] border-lightBlue rounded-full text-sm text-blackPrimary bg-white">
                    <img
                      className="w-[15px] h-[15px] mr-2 object-cover"
                      src={javascript}
                      width={15}
                      height={15}
                      alt="JavaScript"
                    />
                    <p className="whitespace-nowrap">JavaScript</p>
                  </li>

                  <li className="h-9 px-3 flex justify-center items-center border-[2px] border-lightBlue rounded-full text-sm text-blackPrimary bg-white">
                    <img
                      className="w-[15px] h-[15px] mr-2 object-cover"
                      src={TypeScript}
                      width={15}
                      height={15}
                      alt="TypeScript"
                    />
                    <p className="whitespace-nowrap">TypeScript</p>
                  </li>
                </ul>
              </div>
              <div className="price text-base font-bold text-blackPrimary ml-auto">
                <span>$4,500</span>/month
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Profile;
