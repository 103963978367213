import React, {  useEffect } from "react";
import { useDispatch } from "react-redux";
import { setActiveTab } from "./../../../features/tabs/tabsSlice";

import { PopupButton } from "react-calendly";

import accessBlue from "./../../../img/accessBlue.svg";
import recruiting from "./../img/start-recruiting.svg";

const Dashboard = () => {
  
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  const dispatch = useDispatch();

  const changeTab = (index) => {
    dispatch(setActiveTab(index));
  };

  return (
    <section className="dashboard">
      <div className="container max-w-[1058px]">
        <div className="text-block mt-3 mb-9">
          <h1 className="text-2xl font-bold text-blackPrimary">
            Hello, Vinsent 👋
          </h1>
          <p className="text-sm font-normal text-themeGray">
            Here's the current update for today.
          </p>
        </div>

        <div className="card-block mb-16 md:mb-20">
          <ul className="flex justify-center flex-wrap gap-5">
            <li className="p-5 md:p-8 rounded-2xl flex flex-col items-start flex-shrink flex-grow md:basis-[47%] max-w-[520px] text-black bg-white">
              <div className="mb-7 flex items-center">
                <img
                  src={accessBlue}
                  className="mr-4"
                  width={40}
                  height={40}
                  alt="staffing"
                />
                <p className="font-bold text-xl">Hiring engineers with AI</p>
              </div>
              <span className="mb-4 md:mb-7 text-base text-blackPrimary09 font-normal">
                Answer 7 questions (1-2 minutes) and start receiving first
                highly professional and relevant talents right away
              </span>

              <button
                onClick={() => changeTab(2)}
                className="group w-36 mt-auto hover:bg-darkBlue transition-colors h-[54px] px-2 flex justify-center items-center rounded-xl text-base font-medium text-white bg-blue"
              >
                <p className="font-Author text-lg">Get Started</p>
              </button>
            </li>

            <li className="p-5 md:p-8 rounded-2xl flex flex-col items-start flex-shrink flex-grow md:basis-[47%] max-w-[520px] text-black bg-white">
              <div className="mb-7 flex items-center">
                <img
                  src={accessBlue}
                  className="mr-4"
                  width={40}
                  height={40}
                  alt="staffing"
                />
                <p className="font-bold text-xl">
                  Book your free onboarding call
                </p>
              </div>
              <span className="mb-4 md:mb-7 text-base text-blackPrimary09 font-normal">
                Book a half-hour conversation with an international HR
                specialist and receive immediate responses to your inquiries.
              </span>

              <PopupButton
                url="https://calendly.com/yuriy-golikov/30min?hide_event_type_details=1&hide_gdpr_banner=1"
                rootElement={document.getElementById("root")}
                text="Talk to an expert"
                className="group w-44 mt-auto text-blackPrimary border-[2px] border-blue bg-white hover:bg-blue hover:text-white transition-colors h-[54px] px-2 flex justify-center items-center rounded-xl font-Author text-lg font-medium"
              />
            </li>
          </ul>
        </div>

        <div className="flex flex-col items-center">
          <img
            // src={isDarkMode ? recruitingDark : recruiting}
            src={recruiting}
            className="mb-5 mx-auto w-full max-w-[262px]"
            width={262}
            height={262}
            alt="start recruiting"
          />
          <h2 className="mb-2 font-bold text-xl text-center">
            Start recruiting IT professionals for your team
          </h2>
          <p className="w-[270px] sm:w-[410px] mb-5 text-base sm:text-center text-blackPrimary09 font-normal">
            Book a half-hour conversation with an international HR specialist
            and receive immediate responses to your inquiries.
          </p>
          <button
            onClick={() => changeTab(1)}
            className="group w-36 mt-auto hover:bg-darkBlue transition-colors h-[54px] px-2 flex justify-center items-center rounded-xl text-base font-medium text-white bg-blue"
          >
            <p className="font-Author text-lg">Get Started</p>
          </button>
        </div>
      </div>
      
    </section>
  );
};

export default Dashboard;
