import { useRouteError } from "react-router-dom";

import Header from "./../Header/Header";
import HeaderMobile from "./../HeaderMobile/HeaderMobile";
import Footer from "./../Footer/Footer";

export const ErrorPage = () => {
  const error = useRouteError();
  return (
    <div className="root-container">
      <Header />
      <HeaderMobile/>
      <main className="text-center py-40">
        <h1>Oops!</h1>
        <p>Sorry, something went wrong</p>
        <p>{error.statusText ?? error.message}</p>
      </main>
      <Footer/>
    </div >
  );
};
