import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import logo from "./../../../img/logo.svg";
import logoDark from "./../../../img/logo-dark.svg";
import { setThemeState } from "../../../features/theme/themeSlice";

const HeaderSurvey = () => {
  const [theme, setTheme] = useState(sessionStorage.theme);
  const dispatch = useDispatch();  

  useEffect(() => {
    if (sessionStorage.theme === "dark") {
      document.documentElement.classList.add("dark");
      sessionStorage.theme = "dark";
      setTheme("dark");
      dispatch(setThemeState(true));
    } else {
      document.documentElement.classList.remove("dark");
      sessionStorage.theme = "light";
      setTheme("light");
      dispatch(setThemeState(false));
    }
// eslint-disable-next-line
  }, []);

  return (
    <header className="header-pitch h-[68px] md:h-[75px] 2xl:h-[104px] fixed flex items-center top-0 z-50 w-full bg-white dark:bg-themeBlack border-b border-b-lightBlue">
      <div className="container max-w-[1332px] mx-auto px-4">
        <div className="wrapper flex justify-between items-center">
          <NavLink className={`flex object-contain`} to={"/"}>
            <img
              src={theme === "dark" ? logoDark : logo}
              className="logo h-[36px] md:h-[46px] w-auto"
              width={"100"}
              height={"47"}
              alt="logo"
            />
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default HeaderSurvey;
