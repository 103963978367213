import React from "react";
import "./styles.scss";

const CookiesPolicy = () => {
  return (
    <section className="privacy cookies pt-28 pb-24 lg:pt-48">
      <div className="container max-w-[1332px] mx-auto px-4 relative z-10">
        <div className="wrapper max-w-[850px]">
          <h1 className="font-extrabold mb-7 md:mb-10 text-4xl md:text-5xl">
            Cookies Policy
          </h1>

          <ol className="list-wrap">
            <li>
              <h2>Introduction</h2>
              <ol>
                <li>Our website uses cookies.</li>
                <li>
                  We will ask you to consent to our use of cookies in accordance
                  with the terms of this policy when you first visit our
                  website.
                </li>
              </ol>
            </li>
            <li>
              <h2>About cookies</h2>
              <ol>
                <li>
                  A cookie is a file containing an identifier (a string of
                  letters and numbers) that is sent by a web server to a web
                  br/owser and is stored by the br/owser. The identifier is then
                  sent back to the server each time the br/owser requests a page
                  from the server.
                </li>
                <li>
                  Cookies may be either "persistent" cookies or "session"
                  cookies: a persistent cookie will be stored by a web br/owser
                  and will remain valid until its set expiry date, unless
                  deleted by the user before the expiry date; a session cookie,
                  on the other hand, will expire at the end of the user session,
                  when the web br/owser is closed.
                </li>
                <li>
                  Cookies do not typically contain any information that
                  personally identifies a user, but personal information that we
                  store about you may be linked to the information stored in and
                  obtained from cookies.
                </li>
                <li>
                  Cookies can be used by web servers to identify and track users
                  as they navigate different pages on a website and identify
                  users returning to a website
                </li>
              </ol>
            </li>
            <li>
              <h2>Our cookies</h2>
              <ol>
                <li>
                  We use both session and persistent cookies on our website.
                </li>
                <li>
                  The names of the cookies that we use on our website, and the
                  purposes for which they are used, are set out below: we use
                  jv_client_id to recognise a computer when a user visits our
                  website, track users as they navigate the website, improve the
                  website's usability.
                </li>
              </ol>
            </li>
            <li>
              <h2>Analytics cookies</h2>
              <ol>
                <li>
                  We use Google Analytics to analyse the use of our website.
                </li>
                <li>
                  Our analytics service provider generates statistical and other
                  information about website use by means of cookies.
                </li>
                <li>
                  The analytics cookies used by our website have the following
                  names: _ga.
                </li>
                <li>
                  The information generated relating to our website is used to
                  create reports about the use of our website.
                </li>
                <li>
                  Our analytics service provider's privacy policy is available
                  at:
                  <a href="http://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer"> http://www.google.com/policies/privacy/</a>.
                </li>
              </ol>
            </li>
            <li>
              <h2>Blocking cookies</h2>
              <ol>
                <li>
                  Most br/owsers allow you to refuse to accept cookies; for
                  example:
                  <br />
                  <p>
                    (a) in Internet Explorer (version 11) you can block cookies
                    using the cookie handling override settings available by
                    clicking "Tools", "Internet Options", "Privacy" and then
                    "Advanced";
                  </p>
                  <p>
                    (b) in Firefox (version 44) you can block all cookies by
                    clicking "Tools", "Options", "Privacy", selecting "Use
                    custom settings for history" from the drop-down menu, and
                    unticking "Accept cookies from sites"; and
                  </p>
                  <p>
                    (c) in Chrome (version 48), you can block all cookies by
                    accessing the "Customise and control" menu, and clicking
                    "Settings", "Show advanced settings" and "Content settings",
                    and then selecting "Block sites from setting any data" under
                    the "Cookies" heading
                  </p>
                </li>
                <li>
                  We will ask you to consent to our use of cookies in accordance
                  with the terms of this policy when you first visit our website
                </li>
                <li>
                  If you block cookies, you will not be able to use all the
                  features on our website.
                </li>
              </ol>
            </li>
            <li>
              <h2>Deleting cookies</h2>
              <ol>
                <li>
                  You can delete cookies already stored on your computer; for
                  example:
                  <br />
                  <p>
                    (a) in Internet Explorer (version 11), you must manually
                    delete cookie files (you can find instructions for doing so
                    at
                  <a href="http://windows.microsoft.com/en-gb/internet-explorer/delete-manage-cookies#ie=ie-11)" target="_blank" rel="noopener noreferrer"> http://windows.microsoft.com/en-gb/internet-explorer/delete-manage-cookies#ie=ie-11</a>);
                  </p>
                  <p>
                    (b) in Firefox (version 44), you can delete cookies by
                    clicking "Tools", "Options" and "Privacy", then selecting
                    "Use custom settings for history" from the drop-down menu,
                    clicking "Show Cookies", and then clicking "Remove All
                    Cookies"; and
                  </p>
                  <p>
                    (c) in Chrome (version 48), you can delete all cookies by
                    accessing the "Customise and control" menu, and clicking
                    "Settings", "Show advanced settings" and "Clear br/owsing
                    data", and then selecting "Cookies and other site and
                    plug-in data" before clicking "Clear br/owsing data".
                  </p>
                </li>
                <li>
                  Deleting cookies will have a negative impact on the usability
                  of many websites.
                </li>
              </ol>
            </li>
            <li>
              <h2>Our details</h2> <p>You can contact us:</p>
              <p>(a) using our website contact form;</p>
              <p>
                (b) by telephone, on the contact number published on our website
                from 10 am to 8 pm.
              </p>
              <p>
                (c) by email, using the email address published on our website
              </p>
            </li>
          </ol>
        </div>
      </div>
    </section>
  );
};

export default CookiesPolicy;
