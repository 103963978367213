import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import Root from "./components/Root/Root";
import Main from "./components/Main/Main";
import Signup from "./components/Signup/Signup";
import Login from "./components/Login/Login";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Admin from "./components/Admin/Admin";
import Survey from "./components/Survey/Survey";
import HiringCall from "./components/HiringCall/HiringCall";
import { ErrorPage } from "./components/404/ErrorPage";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./components/CookiesPolicy/CookiesPolicy";
import TermsOfUse from "./components/TermsOfUse/TermsOfUse";

import "./index.css";
import "./scss/animation.scss";
import "./scss/dark-theme.scss";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Main />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },      
      {
        path: "/login",
        element: <Login />,
      },      
      {
        path: "/forgot",
        element: <ForgotPassword />,
      },      
      {
        path: "/admin",
        element: <Admin />,
      },      
      {
        path: "/survey/:id",
        element: <Survey />,
      },      
      {
        path: "/hiring-call",
        element: <HiringCall />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/cookies-policy",
        element: <CookiesPolicy />,
      },
      {
        path: "/terms-of-use",
        element: <TermsOfUse />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <RouterProvider router={router} />
  {/* </React.StrictMode> */}
  </Provider>
);
