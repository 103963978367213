import React from "react";

const FooterSurvey = () => {
  return (
    <footer className="relative flex items-center py-6 md:pb-12">
      <div className="container max-w-[1332px] mx-auto px-4">
        <div className="wrapper flex justify-between items-center">
        <p className="w-full mr-auto">
        Copyright © 2023 Talent bank
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterSurvey;
