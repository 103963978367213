import React from "react";
import StepImg from "./StepImg/StepImg";

import {  useParams } from "react-router-dom";
import Step1 from "./Steps/Step1/Step1";
import Step2 from "./Steps/Step2/Step2";
import Step3 from "./Steps/Step3/Step3";
import Step4 from "./Steps/Step4/Step4";
import Step5 from "./Steps/Step5/Step5";
import Step6 from "./Steps/Step6/Step6";
import Step7 from "./Steps/Step7/Step7";

const Survey = () => {
  let num = useParams().id;

  function radio(num) {
    switch (num) {
      case "1":
        return <Step1/>;
      case "2":
        return <Step2/>;
      case "3":
        return <Step3/>;
      case "4":
        return <Step4/>;
      case "5":
        return <Step5/>;
      case "6":
        return <Step6/>;
      case "7":
        return <Step7/>;
      default:
        break;
    }
  }
  function title(num) {
    switch (num) {
      case "1":
        return "Load type";
      case "2":
        return "Primary technologies";
      case "3":
        return "Years of experience";
      case "4":
        return "Your domain";
      case "5":
        return "Your name";
      case "6":
        return "Your e-mail";
      case "7":
        return "Quantity of engineers";
      default:
        break;
    }
  }
  function subTitle(num) {
    switch (num) {
      case "1":
        return "Select the type of employment";
      case "2":
        return "What should be the candidate's main tech focus";
      case "3":
        return "How experienced should be the candidate";
      case "4":
        return "Please specify so AI can offer you even more relevant candidates";
        case "5":
        return "Let's get acquainted";
        case "6":
        return "Please enter your business email";
      case "7":
        return "Let us know how many engineers you are interested to look at";      
      default:
        break;
    }
  }
  return (
    <>
      <div className="pitch container max-w-[1102px] mx-auto px-4 relative z-10 pt-28 md:pt-36 2xl:pt-40 pb-24 md:pb-36 xl:pb-32">

        <div className="wrapper flex flex-col items-center xl:gap-20 xl:flex-row xl:items-start xl:justify-center">
          {/* <div className="choice flex flex-col shrink items-center text-center mb-11 md:mb-20 xl:mb-0 max-w-[730px] w-full"> */}
          <div className="choice flex flex-col shrink items-center text-center max-w-[730px] w-full">
           {+num < 8 && <h1 className="mb-4 max-w-[400px] text-lg md:text-xl">
              Answer<span className="font-bold text-blue"> {`${8 - num}`} </span>questions so our AI can match you with the best relevant tech talents
            </h1>}
            <ul className="flex gap-3 mb-10">
                <li className={`w-4 h-4 flex items-center justify-center rounded-full border ${+num === 1 ? 'border-blue':'border-transparent'} ${+num === 1 ? 'dark:border-blue':'dark:border-transparent'} bg-white dark:bg-transparent`}>
                    <span className={`w-[6px] h-[6px] rounded-full ${+num === 1 ? 'bg-blue':'bg-lightBlue'}`}></span>
                </li>
                <li className={`w-4 h-4 flex items-center justify-center rounded-full border ${+num === 2 ? 'border-blue':'border-transparent'} ${+num === 2 ? 'dark:border-blue':'dark:border-transparent'} bg-white dark:bg-transparent`}>
                    <span className={`w-[6px] h-[6px] rounded-full ${+num === 2 ? 'bg-blue':'bg-lightBlue'}`}></span>
                </li>
                <li className={`w-4 h-4 flex items-center justify-center rounded-full border ${+num === 3 ? 'border-blue':'border-transparent'} ${+num === 3 ? 'dark:border-blue':'dark:border-transparent'} bg-white dark:bg-transparent`}>
                    <span className={`w-[6px] h-[6px] rounded-full ${+num === 3 ? 'bg-blue':'bg-lightBlue'}`}></span>
                </li>
                <li className={`w-4 h-4 flex items-center justify-center rounded-full border ${+num === 4 ? 'border-blue':'border-transparent'} ${+num === 4 ? 'dark:border-blue':'dark:border-transparent'} bg-white dark:bg-transparent`}>
                    <span className={`w-[6px] h-[6px] rounded-full ${+num === 4 ? 'bg-blue':'bg-lightBlue'}`}></span>
                </li>
                <li className={`w-4 h-4 flex items-center justify-center rounded-full border ${+num === 5 ? 'border-blue':'border-transparent'} ${+num === 5 ? 'dark:border-blue':'dark:border-transparent'} bg-white dark:bg-transparent`}>
                    <span className={`w-[6px] h-[6px] rounded-full ${+num === 5 ? 'bg-blue':'bg-lightBlue'}`}></span>
                </li>
                <li className={`w-4 h-4 flex items-center justify-center rounded-full border ${+num === 6 ? 'border-blue':'border-transparent'} ${+num === 6 ? 'dark:border-blue':'dark:border-transparent'} bg-white dark:bg-transparent`}>
                    <span className={`w-[6px] h-[6px] rounded-full ${+num === 6 ? 'bg-blue':'bg-lightBlue'}`}></span>
                </li>
                <li className={`w-4 h-4 flex items-center justify-center rounded-full border ${+num === 7 ? 'border-blue':'border-transparent'} ${+num === 7 ? 'dark:border-blue':'dark:border-transparent'} bg-white dark:bg-transparent`}>
                    <span className={`w-[6px] h-[6px] rounded-full ${+num === 7 ? 'bg-blue':'bg-lightBlue'}`}></span>
                </li>
            </ul>

            <StepImg num={num}/>

            <p className="mb-4 text-4xl md:text-5xl font-semibold font-Author max-w-[630px]">{title(num)}</p>
            {<p className="mb-8 text-xl dark:text-themeGray">{subTitle(num)}</p>}
            
            {radio(num)}
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Survey;
