import React from "react";
import "./styles.scss"
import { NavLink } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <section className="privacy pt-28 pb-24 lg:pt-48">
      <div className="container max-w-[1332px] mx-auto px-4 relative z-10">
        <div className="wrapper max-w-[850px]">
        <h1 className="font-extrabold mb-7 md:mb-10 text-4xl md:text-5xl">Privacy Policy</h1>
        <p className="top-message">
          We respect your personal data. That’s why we’ve created a
          straightforward Privacy Policy explaining what information we collect
          when you visit devbrother.com or communicate with us by any means
          (including email, phone, messengers, and more). The Privacy Policy
          reveals how you control the collected information, how we use this
          data, and how to contact us. It also describes what measures we take
          to protect your data like the rules requested by General Data
          Protection Regulation (<a href="https://gdpr.eu/" target="_blank" rel="noopener noreferrer">GDPR</a>).
        </p>
        <h2>Contact details</h2>
        <p>
          If you have any questions about the privacy policy, feel free to
          contact us.
        </p>
        <p>We’ll get back to you in 24 hours.</p> <h3>Offices</h3>
        <div className="offices">
          <p>+(1) 929-629-8710</p> <p>10010, USA, NY</p>
          <p>43 West 23rd st.</p>
        </div>
        <div className="offices">
          <p>+(48) 572-364-123</p> <p>80-309, Poland, Gdańsk</p>
          <p>aleja Grunwaldzka, 472D Olivia Six</p>
        </div>
        <div className="offices">
          <p>+(380) 938-922-544</p> <p>02000, Ukraine, Kyiv</p>
          <p>Peremohy Ave.26</p>
        </div>
        <div className="offices">
          <p>+(380) 938-922-544</p> <p>61000, Ukraine, Kharkiv</p>
          <p>Bakulina 4A</p>
        </div>
        <h3>Email</h3>
        <a href="mailto:contact@devbrother.com" target="_blank" rel="noopener noreferrer">
          contact@devbrother.com
        </a>
        <h2>Who are we?</h2>
        <p>
          The administrator of your data is (company name - suggested
          LEADINTELLIGENCE SP. Z O.O. due to the ongoing audit).
        </p>
        <p>Here’s how you can contact us:</p>
        <ul>
          <li className="marked-item">
            Using the &nbsp;<a href="https://devbrother.com/about#contact-form" target="_blank" rel="noopener noreferrer">contact form</a>
            &nbsp;on our website
          </li>
          <li className="marked-item">
            Calling the contact number published on our website from 9 AM to 9
            PM
          </li>
          <li className="marked-item">
            Writing to the email address published on our website
          </li>
        </ul>
        <h2>Why do we collect personal information?</h2>
        <p>
          DevBrother collects information to offer better services to clients.
        </p>
        <p>Generally, we collect information for marketing purposes.</p>
        <ul>
          <li className="marked-item">
            If you leave a contact request or send us an email, we’ll collect
            and process your data based on your requests. In this case, we’ve a
            legitimate interest in building client relationships with you.
          </li>
          <li className="marked-item">
            If you download an eBook from our website, we collect your data to
            analyze who has downloaded our marketing material. We can also
            contact you to find out if there’s a business opportunity for our
            cooperation. It’s done based on the legitimate interest to follow
            marketing practices and build client relationships with you.
          </li>
          <li className="marked-item">
            If you subscribe to our blog, we’ll send you emails with our new
            articles, achievements, and relevant tech trends. We tend to
            personalize our emails based on your personal information to make
            them more useful.
          </li>
          <li className="marked-item">
            We add your personal data and information about your company that we
            receive from you through a website or by email to our CRM system. We
            analyze it for marketing purposes. We have a legitimate interest in
            knowing our client’s profile.
          </li>
        </ul>
        <p>
          If you no longer want us to process your personal data, you should
          contact us in any convenient way and inform us about your intention.
        </p>
        <p>
          Also, you can unsubscribe from our communications by clicking the
          Unsubscribe button under any email you receive from us.
        </p>
        <p>
          Mind that after clicking the Unsubscribe button, we don’t delete your
          data automatically. You need to request us to do so by sending an
          email to the address above.
        </p>
        <h2>What personal information do we collect?</h2>
        <p>
          We may collect, store, and use the following personal information:
        </p>
        <ul>
          <li className="marked-item">
            Data you provide filling in personal information on our website like
            your name, email address, company name, title, and country.
          </li>
          <li className="marked-item">
            Information we receive when you use devbrother.com, such as IP
            address, geographical location, browser type and version, operating
            system, referral source, length of visit, page views, and website
            navigation paths.
          </li>
          <li className="marked-item">
            Information you give us to subscribe to our email notifications
            and/or newsletters (including name and email address).
          </li>
          <li className="marked-item">
            Data you give us applying for the job with your CV and any
            additional information by oral or written means.
          </li>
        </ul>
        <h2>How do we use the collected information?</h2>
        <p>We may use your personal information to:</p>
        <ul>
          <li className="marked-item">Personalize our website for you</li>
          <li className="marked-item">Administer our website and business</li>
          <li className="marked-item">
            Send statements, invoices or payment reminders to you, and collect
            payments from you
          </li>
          <li className="marked-item">
            Send you non-marketing commercial communications
          </li>
          <li className="marked-item">Send you emails that you requested</li>
          <li className="marked-item">
            Send you blog newsletters if you subscribe
          </li>
          <li className="marked-item">
            Send you our newsletters that you requested (you can unsubscribe at
            any time)
          </li>
          <li className="marked-item">
            Send you marketing communications related to our business or the
            businesses of selected third parties which we think may be
            interesting for you (you can inform us at any time if you no longer
            want to receive marketing communications)
          </li>
        </ul>
        <p>
          We proceed only with the information received from you through a
          website or by email. We’ll use that information in accordance with the
          permission you grant to us.
        </p>
        <h2>Who has access to your personal information?</h2>
        <p>
          We may share your personal data with our employees if it’s necessary
          for the purposes described in this policy.
        </p>
        <p>
          We may share your personal information with any member of our group of
          companies (this means our subsidiaries, our ultimate holding company,
          and its subsidiaries) if it’s necessary for the purposes described in
          this policy.
        </p>
        <p>
          We also share data with the service providers and third-party vendors
          who perform functions on our behalf (including accountants, lawyers,
          website management, hosting, tech support, and marketing analysis).
        </p>
        <p>There are some other cases when we can share your data:</p>
        <ul>
          <li className="marked-item">To the extent that’s required by law</li>
          <li className="marked-item">
            In connection with any ongoing or prospective legal proceeding
          </li>
          <li className="marked-item">
            To establish, exercise, or defend our legal rights (including
            providing information to others for fraud prevention)
          </li>
        </ul>
        <h2>How do we store personal information?</h2>
        <p>
          We want to share our data retention policies and procedures. We’ve
          designed them to comply with our legal obligations associated with the
          retention and deletion of personal information, required by
          <a href="https://gdpr.eu/" target="_blank" rel="noopener noreferrer">GDPR</a>.
        </p>
        <p>
          We do not store collected information longer than it’s necessary for
          our purposes or if we no longer have a legal basis to keep it.
        </p>
        <ul>
          <li className="marked-item">
            We delete your data as soon as we receive a request from you to do
            so. You can ask us to delete information by sending an email.
          </li>
          <li className="marked-item">
            We store information as long as we have a business relationship. If
            we don’t start a business relationship, we won’t store it longer
            than required for marketing analysis. We won’t keep your data for
            more than a year.
          </li>
        </ul>
        <p>
          However, we’ll retain documents (including electronic documents)
          containing personal information:
        </p>
        <ul>
          <li className="marked-item">To the extent that we are allowed by law</li>
          <li className="marked-item">
            If we believe the documents may be relevant to any ongoing or
            prospective legal proceedings
          </li>
          <li className="marked-item">
            To establish, exercise, or defend our legal rights (including
            providing information to others for fraud prevention)
          </li>
        </ul>
        <h2>Is your personal information secured?</h2>
        <p>
          We take reasonable technical and organizational precautions to prevent
          the loss, misuse, or alteration of your personal information, as
          required by <a href="https://gdpr.eu/" target="_blank" rel="noopener noreferrer">GDPR</a>.
        </p>
        <p>
          We store all the personal information you provide on our secure
          (password- and firewall-protected) servers.
        </p>
        <ul>
          <li className="marked-item">
            All our employees, consultants, and any recipients of your
            information are bound by confidentiality. They cannot process your
            data for purposes other than described in this Privacy Policy
          </li>
          <li className="marked-item">
            We follow the Information Security Policy, which is obligatory for
            all our consultants, contractors, and other people who have
            authorized access to information.
          </li>
          <li className="marked-item">
            We apply Information Security Policy to all the data stored on
            physical or electronic carriers, along with software and hardware
            that we possess or use temporarily.
          </li>
          <li className="marked-item">
            We have obligatory training for our employees and consultants on
            privacy and security.
          </li>
          <li className="marked-item">
            We constantly gather and analyze data about new and existing threats
            and vulnerabilities, attacks on other organizations, along with the
            information about actual security controls.
          </li>
          <li className="marked-item">
            We update our servers, workstations, and internet gateway devices
            with the latest antivirus software.
          </li>
        </ul>
        <p>We want to safeguard the privacy of our website visitors.</p>
        <ul>
          <li className="marked-item">
            We will ask you to agree to our use of cookies under the terms of
            this policy when you first visit our website.
            <NavLink to={"/cookies-policy"}> Read more about cookies</NavLink>.
          </li>
          <li className="marked-item">
            Personal information that you publish on our website or submit for
            publication may be available via the Internet worldwide. We can't
            prevent the use or misuse of such information by others.
          </li>
          <li className="marked-item">
            You expressly agree to the transfers of personal information
            described in ‘What personal information do we collect?
          </li>
        </ul>
        <h2>What are your rights?</h2>
        <p>
          We follow the rules offered by GDPR and treat our visitors equally.
        </p>
        <p>You have the following rights:</p>
        <ul>
          <li className="marked-item">
            <b>Right of access.</b> You may ask us to provide you with any
            personal information we hold about you.
          </li>
          <li className="marked-item">
            <b>Right to be informed.</b> You have the right to know how and why
            we process your personal data.
          </li>
          <li className="marked-item">
            <b>Right to erasure.</b> You have the right to request for deleting
            your data.
          </li>
          <li className="marked-item">
            <b>Right to restrict processing.</b> You may command us at any time
            to stop processing your data for marketing purposes.
          </li>
          <li className="marked-item">
            <b>Right to rectification.</b> You may ask us to correct your
            personal data if the information we have is inaccurate or outdated.
          </li>
        </ul>
        <p>
          We may withhold personal information that you request to the extent
          permitted by law. In practice, you either agree to the use of your
          personal information for marketing purposes or opt out of this option.
        </p>
        <h2>Do we update this Privacy Policy?</h2>
        <ul>
          <li className="marked-item">
            We may update this policy from time to time by publishing a new
            version on our website.
          </li>
          <li className="marked-item">
            You should check this page occasionally to ensure you’re happy with
            any changes to this policy.
          </li>
          <li className="marked-item">
            We may notify you of changes to this policy by email.
          </li>
        </ul>
        <h2>What about cookies?</h2> <p>Our website uses cookies.</p>
        <p>
          A cookie is a text file with data — like the website's name and unique
          ID tags — that we use to identify your computer next time.
        </p>
        <NavLink
        to={"/cookies-policy"}
        >
         Read more
        </NavLink>
        <p>
          <a href="https://devbrother.com/about#contact-form" target="_blank" rel="noopener noreferrer">Contact us</a>
        </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
