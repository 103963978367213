import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import arrow from "./../../../../img/Survey/arrow-left-blue.svg";
import "./style.scss";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1.5,
    marginLeft: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : "rgba(59, 135, 255, 1)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 21,
    height: 21,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#DEDFE1" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function Step3() {
  let storage = JSON.parse(sessionStorage.getItem("3"));

  const empty = false;
  // const [empty, setEmpty] = React.useState(true);
  const [state, setState] = React.useState(
    storage || {
      "<1": false,
      "2-3": false,
      "4-5": false,
      "5+": false,
    }
  );

  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      leftArrowRef.current.click();
    }
    if (event.key === "ArrowRight") {
      rightArrowRef.current.click();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("3", JSON.stringify(state));
    // if(state && Object.values(state).includes(true)){
    //   setEmpty(false);
    // } else setEmpty(true);
  }, [state]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className="max-w-[480px] flex w-full flex-col items-center gap-8">
      <FormGroup className="step-3 w-full flex justify-center items-center flex-wrap gap-x-2 gap-y-4">
        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["<1"] === true ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >         

          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="<1"
                name="<1"
                checked={state["<1"]}
              />
            }
            label="<1"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["2-3"] === true ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="2-3"
                name="2-3"
                checked={state["2-3"]}
              />
            }
            label="2-3"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["4-5"] === true ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="4-5"
                name="4-5"
                checked={state["4-5"]}
              />
            }
            label="4-5"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["5+"] === true ? "border-softBlue bg-softBlue" : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="5+"
                name="5+"
                checked={state["5+"]}
              />
            }
            label="5+"
          />
        </div>
      </FormGroup>
      <div className="w-full flex justify-between items-center">
        <NavLink
          ref={leftArrowRef}
          to={"/survey/2"}
          className="h-12 w-12 flex items-center justify-center shrink-0 rounded-xl bg-white transition-colors hover:bg-lightBlue border border-blue"
        >
          <img
            className="-translate-x-[2px]"
            src={arrow}
            width={12}
            height={20}
            alt="arrow"
          />
        </NavLink>
        <NavLink
          to={"/survey/4"}
          className={`${
            empty ? "pointer-events-none opacity-80" : ""
          } mx-auto h-12 w-28 flex items-center justify-center shrink-0 rounded-xl font-medium text-white bg-blue transition-colors hover:bg-darkBlue`}
        >
          OK
        </NavLink>
        <NavLink
          ref={rightArrowRef}
          to={"/survey/4"}
          className={`${
            empty ? "pointer-events-none opacity-40" : ""
          } h-12 w-12 flex items-center justify-center shrink-0 rounded-xl bg-white transition-colors hover:bg-lightBlue border border-blue`}
        >
          <img
            className="translate-x-[2px] rotate-180"
            src={arrow}
            width={12}
            height={20}
            alt="arrow"
          />
        </NavLink>
      </div>
    </div>
  );
}
