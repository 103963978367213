import { Outlet , ScrollRestoration } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Header from "./../Header/Header";
import Footer from "./../Footer/Footer";
import HeaderMobile from "./../HeaderMobile/HeaderMobile";
import HeaderSurvey from "../Survey/HeaderSurvey/HeaderSurvey";
import FooterSurvey from "../Survey/FooterSurvey/FooterSurvey";
import HeaderAdmin from "../Admin/HeaderAdmin/HeaderAdmin";

const Root = () => {

  let location = useLocation();

  return (
    <div className="root-container">
      { location.pathname.includes("survey") ? <HeaderSurvey/> :
      location.pathname.includes("login")
      || location.pathname.includes("forgot")
      || location.pathname.includes("signup")
      || location.pathname.includes("admin")
      ? <></> : <> <Header /> <HeaderMobile /></>}
      {location.pathname.includes("admin") && <HeaderAdmin/>}
      <main>
        <Outlet />
      </main>
      { location.pathname.includes("survey") ? <FooterSurvey/> :
      location.pathname.includes("login")
      || location.pathname.includes("forgot")
      || location.pathname.includes("signup")
      || location.pathname.includes("admin")
      ? <></> : <Footer />}      
      <ScrollRestoration />
    </div>
    
  );
};

export default Root;
