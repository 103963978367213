import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import "./style.scss";
import { setActiveTab, setTabsContent } from "./../../features/tabs/tabsSlice";
import Dashboard from "./Dashboard/Dashboard";

import { ReactComponent as IconDashboard } from "./icons/dashboard.svg";
import { ReactComponent as IconSearch } from "./icons/search.svg";
import { ReactComponent as IconStar } from "./icons/star.svg";
import { ReactComponent as MoreVertIcon } from "./icons/more-vertical.svg";
import { ReactComponent as SettingsIcon } from "./icons/settings.svg";
import logo from "./../../img/logo.svg";
import AccountSettings from "./AccountSettings/AccountSettings";
import SearchAI from "./SearchAI/SearchAI";
import SearchTalent from "./SearchTalent/SearchTalent";
import Profile from "./Profile/Profile";

function Tab(props) {
  const { label, icon, isActive, onClick } = props;

  return (
    <li
      className={`tab flex items-center h-11 px-5 py-3 mb-2 rounded-lg cursor-pointer ${
        isActive ? "active" : ""
      }`}
      onClick={onClick}
    >
      <span className="tab-icon flex w-5 h-5 mr-3">{icon}</span>
      <span className="tab-label text-sm font-bold">
        {label}
      </span>
    </li>
  );
}

function Tabs(props) {
  const { tabs, activeTab, onTabChange } = props;

  return (
    <ul className="tab-list">
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={tab.label}
          icon={tab.icon}
          isActive={index === activeTab}
          onClick={() => onTabChange(index)}
        />
      ))}
    </ul>
  );
}

function TabContent(props) {
  const { children } = props;
  return <div className="tab-content">{children}</div>;
}

const Admin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.tabs.activeTab);
  const tabsContent = useSelector((state) => state.tabs.tabsContent);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  const handleTabChange = (index, page) => {
    dispatch(setActiveTab(index));
    dispatch(setTabsContent(page));
  };

  const tabs = [
    { label: "Dashboard", content: <Dashboard />, icon: <IconDashboard /> },
    { label: "Search talent", content: <SearchTalent/>, icon: <IconSearch /> },
    { label: "Search with AI", content: <SearchAI/>, icon: <IconStar /> },
  ];

  const userName = "Vinsent Vangog";
  const userEmail = "mail@asd.com";
  const initials = userName
    .split(" ")
    .map((word) => word.charAt(0))
    .join("")
    .toUpperCase();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleGoMain = () => {
    setAnchorEl(null);
    navigate("/");
  };

  return (
      
      <div className="admin-page pt-24 lg:pt-5">
        <div className="container max-w-[1392px] mr-auto px-4 relative z-10">
          <div className="wrapper flex gap-16">
            <aside className="aside fixed hidden lg:flex flex-col justify-between w-full max-w-[240px] h-[95vh] pt-9 bg-white rounded-2xl border border-[#4B5865]/[.12]">
              <div className="aside-main px-4">
                <img
                  onClick={() => handleTabChange(0)}
                  // src={isDarkMode ? logoDark : logo}
                  src={logo}
                  className="logo h-[39px] w-auto mb-14 mx-auto cursor-pointer"
                  width={"110"}
                  height={"52"}
                  alt="logo"
                />
                <nav>
                  <Tabs
                    tabs={tabs}
                    activeTab={activeTab}
                    onTabChange={handleTabChange}
                  />
                </nav>
              </div>
              <div className="aside-footer px-4 py-6 bg-blue012 rounded-b-2xl border-t border-[#4B5865]/[.12]">
                <div className="wrap flex items-center">
                  <Avatar
                    sx={{
                      bgcolor: "#ffffff",
                      color: "rgba(59, 135, 255, 1)",
                      fontSize: 16,
                      fontWeight: 700,
                      lineHeight: "19px",
                      border: "1px solid rgba(59, 135, 255, 1)",
                    }}
                  >
                    {initials}
                  </Avatar>
                  <div className="ml-2 mr-auto">
                    <p className="text-base font-bold text-blackPrimary">
                      {userName}
                    </p>
                    <span className="text-sm font-normal text-themeGray">
                      {userEmail}
                    </span>
                  </div>

                  <IconButton
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                      width: 24,
                      height: 24,
                      padding: 0,
                    }}
                    id="fade-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleOpenMenu}
                  >
                    <MoreVertIcon />
                  </IconButton>

                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    slotProps={{
                      paper: { style: { borderRadius: 16, width: 215 } },
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    anchorOrigin={{
                      vertical: -100,
                      horizontal: -100,
                    }}
                  >
                    <MenuItem
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(243, 248, 255, 1)",
                        },
                        padding: "12px 20px",
                        color: "rgba(20, 15, 27, 1)",
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: "21px",
                      }}
                      onClick={() => {
                        handleClose();
                        dispatch(setTabsContent("settings"));
                      }}
                    >
                      <SettingsIcon className="mr-3" />
                      Account settings
                    </MenuItem>
                    <MenuItem
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(243, 248, 255, 1)",
                        },
                        padding: "12px 20px 12px 22px",
                        color: "rgba(20, 15, 27, 1)",
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: "21px",
                      }}
                      onClick={handleGoMain}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </aside>

            <div className="main-content w-full ml-0 lg:ml-[270px] xl:ml-[300px] pb-32">
              {!tabsContent && (
                <TabContent>{tabs[activeTab].content}</TabContent>
              )}
              {tabsContent ==="settings" && <AccountSettings/>}
              {tabsContent ==="profile" && <Profile/>}
              {/* {!tabsContent && <AccountSettings/>} */}
            </div>
          </div>
        </div>
      </div>
  );
};

export default Admin;
