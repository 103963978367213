import React, { useEffect , useRef } from "react";
import { NavLink } from "react-router-dom";
import TextField from "@mui/material/TextField";

import arrow from "./../../../../img/Survey/arrow-left-blue.svg";
import "./style.scss";

export default function Step6() {
  
  let storage = JSON.parse(sessionStorage.getItem("6"));
  const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
  
  const [empty, setEmpty] = React.useState(true);
  const [state, setState] = React.useState(storage || {
    type: "",
  });


  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      leftArrowRef.current.click();
    }
    if (event.key === 'ArrowRight') {
      rightArrowRef.current.click();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("6", JSON.stringify(state));
    if (!emailRegex.test(state["type"])) {
      // invalid email
      setEmpty(true);
    } else {
      // valid email
      setEmpty(false);
    }
    // eslint-disable-next-line
  }, [state]);

  return (
    <div className="step-6 flex w-full flex-col items-center gap-8">
      <TextField
        id="email"
        value={state["type"]}
        placeholder={"Your e-mail"}
        onChange={(event) => {
          setState({
            type: event.target.value,
          });
        }}
      />

      <div className="w-full flex justify-between items-center">
        <NavLink
          ref={leftArrowRef}
          to={"/survey/5"}
          className="h-12 w-12 flex items-center justify-center shrink-0 rounded-xl bg-white transition-colors hover:bg-lightBlue border border-blue"
        >
          <img
            className="-translate-x-[2px]"
            src={arrow}
            width={12}
            height={20}
            alt="arrow"
          />
        </NavLink>

        <NavLink
          to={"/survey/7"}
          className={`${empty ? "pointer-events-none opacity-80" : ""} mx-auto h-12 w-28 flex items-center justify-center shrink-0 rounded-xl font-medium text-white bg-blue transition-colors hover:bg-darkBlue`}          
        >
          OK
        </NavLink>

        <NavLink
          ref={rightArrowRef}
          to={!empty ? "/survey/7": undefined }
          className={`${empty ? "pointer-events-none opacity-40" : ""} h-12 w-12 flex items-center justify-center shrink-0 rounded-xl bg-white transition-colors hover:bg-lightBlue border border-blue`}
        >
          <img
            className="translate-x-[2px] rotate-180"
            src={arrow}
            width={12}
            height={20}
            alt="arrow"
          />
        </NavLink>
      </div>
    </div>
  );
}
