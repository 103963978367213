import { createSlice } from '@reduxjs/toolkit';

const tabsSlice = createSlice({
  name: 'tabs',
  initialState: {
    activeTab: 0,
    tabsContent : ""
    // tabsContent : true,
  },
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    setTabsContent(state, action) {
      state.tabsContent = action.payload;
    },
  },
});

export const { setActiveTab, setTabsContent } = tabsSlice.actions;

export default tabsSlice.reducer;