import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { fetchData } from "./../../../../functions/fetchData";
import arrow from "./../../../../img/Survey/arrow-left-blue.svg";
import ReactIconBlue from "./../../../../img/Survey/icons/ReactIconBlue.svg";
import TypeScript from "./../../../../img/Survey/icons/TypeScript.svg";
import NodeJS from "./../../../../img/Survey/icons/NodeJS.svg";
import Python from "./../../../../img/Survey/icons/Python.svg";
import Blockchain from "./../../../../img/Survey/icons/Blockchain.svg";
import PHP from "./../../../../img/Survey/icons/PHP.svg";
import Go from "./../../../../img/Survey/icons/Go.svg";
import check from "./../../../../img/Survey/icons/check-white.svg";
import RoR from "./../../../../img/Survey/icons/ROR.svg";
import NET from "./../../../../img/Survey/icons/NET.svg";
import Devsecops from "./../../../../img/Survey/icons/Devsecops.svg";
import UX from "./../../../../img/Survey/icons/UX.svg";
import Gaming from "./../../../../img/Survey/icons/Gaming.svg";
import QA from "./../../../../img/Survey/icons/QA.svg";
import error from "./../../../../img/Survey/icons/error.svg";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./style.scss";

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1.5,
    marginLeft: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : "rgba(59, 135, 255, 1)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 21,
    height: 21,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#DEDFE1" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function Step2() {
  let storage = JSON.parse(sessionStorage.getItem("2"));
  let technologiesLength = 0;

  const navigate = useNavigate();
  const inputRef = useRef(null);
  const btnRef = useRef(null);
  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);

  const [shake, setShake] = React.useState(false);
  const [overflow, setOverflow] = React.useState(false);
  const [empty, setEmpty] = React.useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [state, setState] = React.useState(
    storage || {
      React: false,
      NodeJS: false,
      TypeScript: false,
      ReactNative: false,
      RoR: false,
      ".NET": false,
      Solidity: false,
      DevSecOps: false,
      "UX/UI": false,
      "Unity/Unreal": false,
      Go: false,
      PHP: false,
      Python: false,
      QA: false,
      Other: "",
    }
  );

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      leftArrowRef.current.click();
    }
    if (event.key === "ArrowRight") {
      rightArrowRef.current.click();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (isFocused) {
      btnRef.current.classList.add("on");
      inputRef.current.classList.remove("added");
      inputRef.current.placeholder = "Type your answer";
      inputRef.current.style.paddingLeft = "20px";
      inputRef.current.style.paddingRight = "50px";
      inputRef.current.classList.add("focused");
    } else if (!state["Other"]) {
      btnRef.current.classList.remove("on");
      inputRef.current.classList.remove("added");
      inputRef.current.placeholder = "Other";
      inputRef.current.classList.remove("focused");
    } else if (state["Other"]) {
      btnRef.current.classList.remove("on");
      inputRef.current.classList.add("added");
      inputRef.current.style.paddingLeft = "52px";
      inputRef.current.style.paddingRight = "10px";
      inputRef.current.classList.remove("focused");
    }
    // eslint-disable-next-line
  }, [isFocused]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    sessionStorage.setItem("2", JSON.stringify(state));

    // eslint-disable-next-line
    technologiesLength = Object.values(state).filter(
      // eslint-disable-next-line
      (el) => el == true || (typeof el === "string" && el.trim().length !== 0)
    ).length;

    if (technologiesLength > 2) {
      setShake(true);
      setOverflow(true);
      setTimeout(() => {
        setShake(false);
      }, 1200);
    } else setOverflow(false);

    if (
      ((state && Object.values(state).includes(true)) ||
        state["Other"].length >= 2) &&
      technologiesLength <= 2
    ) {
      setEmpty(false);
    } else setEmpty(true);
  }, [state]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className="relative flex w-full flex-col items-center gap-8">
      <FormGroup className="step-2 lg:mb-0 w-full flex justify-center items-center flex-wrap gap-x-2 gap-y-4 order-2 md:order-1">
        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["React"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={ReactIconBlue}
            width={24}
            height={24}
            alt="React"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="React"
                name="React"
                checked={state["React"]}
              />
            }
            label="React"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["NodeJS"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={NodeJS}
            width={24}
            height={24}
            alt="NodeJS"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="NodeJS"
                name="NodeJS"
                checked={state["NodeJS"]}
              />
            }
            label="NodeJS"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["TypeScript"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={TypeScript}
            width={24}
            height={24}
            alt="TypeScript"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="TypeScript"
                name="TypeScript"
                checked={state["TypeScript"]}
              />
            }
            label="TypeScript"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["ReactNative"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={ReactIconBlue}
            width={24}
            height={24}
            alt="ReactNative"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="ReactNative"
                name="ReactNative"
                checked={state["ReactNative"]}
              />
            }
            label="ReactNative"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["RoR"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={RoR}
            width={24}
            height={24}
            alt="RoR"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="RoR"
                name="RoR"
                checked={state["RoR"]}
              />
            }
            label="RoR"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state[".NET"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={NET}
            width={24}
            height={24}
            alt=".NET"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value=".NET"
                name=".NET"
                checked={state[".NET"]}
              />
            }
            label=".NET"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["Solidity"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={Blockchain}
            width={24}
            height={24}
            alt="Solidity"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="Solidity"
                name="Solidity"
                checked={state["Solidity"]}
              />
            }
            label="Solidity"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["DevSecOps"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={Devsecops}
            width={24}
            height={24}
            alt="DevSecOps"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="DevSecOps"
                name="DevSecOps"
                checked={state["DevSecOps"]}
              />
            }
            label="DevSecOps"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["UX/UI"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={UX}
            width={24}
            height={24}
            alt="UX/UI"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="UX/UI"
                name="UX/UI"
                checked={state["UX/UI"]}
              />
            }
            label="UX/UI"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["Unity/Unreal"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={Gaming}
            width={24}
            height={24}
            alt="Unity/Unreal"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="Unity/Unreal"
                name="Unity/Unreal"
                checked={state["Unity/Unreal"]}
              />
            }
            label="Unity/Unreal"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["Go"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={Go}
            width={24}
            height={24}
            alt="Go"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="Go"
                name="Go"
                checked={state["Go"]}
              />
            }
            label="Go"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["PHP"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={PHP}
            width={24}
            height={24}
            alt="PHP"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="PHP"
                name="PHP"
                checked={state["PHP"]}
              />
            }
            label="PHP"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["Python"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={Python}
            width={24}
            height={24}
            alt="Python"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="Python"
                name="Python"
                checked={state["Python"]}
              />
            }
            label="Python"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue ${
            state["QA"] === true
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <img
            className="absolute w-auto h-6 top-1/2 left-[20px] -translate-y-1/2 pointer-events-none"
            src={QA}
            width={24}
            height={24}
            alt="QA"
          />
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <IOSSwitch
                className="xl:text-lg"
                onChange={handleChange}
                value="QA"
                name="QA"
                checked={state["QA"]}
              />
            }
            label="QA"
          />
        </div>

        <div
          className={`hover:shadow hover:bg-softBlue other-wrap ${
            state["Other"].length > 0
              ? "border-softBlue bg-softBlue"
              : "border-borderGray bg-lightGray"
          } relative h-[56px] w-full sm:basis-[47%] md:basis-auto md:w-[230px] flex items-center rounded-lg border transition-colors`}
        >
          <input
            ref={inputRef}
            className="block w-full h-full pl-5 pr-3 outline-none placeholder:text-blackPrimary placeholder:text-[15px] text-[15px] font-bold rounded-lg border-0 bg-transparent"
            name="Other"
            id="Other"
            type="text"
            value={state["Other"]}
            placeholder="Other"
            autoComplete="off"
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => setIsFocused(false)}
            onKeyDown={handleKeyPress}
            onChange={(event) => {
              setState({
                ...state,
                [event.target.name]: event.target.value,
              });
            }}
          />

          <p className="hidden absolute left-[22px] w-[18px] h-[18px] justify-center items-center bg-blue rounded-sm pointer-events-none">
            <img
              className="w-[14px] h-[12px]"
              src={check}
              width={14}
              height={12}
              alt="check"
            />
          </p>

          <div
            ref={btnRef}
            className="hidden absolute z-10 inset-y-0 right-1 items-center"
          >
            <button
              id="btn"
              className="relative w-[40px] h-[40px] flex justify-center items-center bg-blue rounded-lg hover:bg-darkBlue transition-colors"
            >
              <img
                className="w-[14px] h-[12px]"
                src={check}
                width={14}
                height={12}
                alt="check"
              />
            </button>
          </div>
        </div>
      </FormGroup>

      <p
        className={`${
          shake ? "animated-button " : ""
        }h-10 w-full max-w-[360px] px-2 flex items-center justify-center rounded-xl bg-softYellow text-sm order-1 md:order-2 shadow-sm whitespace-nowrap dark:text-themeBlackText`}
      >
        <img
          className={`${overflow ? "overflow " : ""}w-auto h-[20px] me-2`}
          src={error}
          width={20}
          height={20}
          alt="React"
        />
        <span className="font-bold">Please,</span>&nbsp;choose not more than 2
        technologies
      </p>

      <p
        className={`${
          shake ? "animated-button " : ""
        }h-10 w-full max-w-[360px] px-2 flex items-center justify-center rounded-xl bg-softYellow text-sm order-3 md:hidden shadow-sm whitespace-nowrap dark:text-themeBlackText`}
      >
        <img
          className={`${overflow ? "overflow " : ""}w-auto h-[20px] me-2`}
          src={error}
          width={20}
          height={20}
          alt="React"
        />
        <span className="font-bold">Please,</span>&nbsp;choose not more than 2
        technologies
      </p>

      <div className="w-full flex justify-between items-center order-3 md:order-3">
        <NavLink
          ref={leftArrowRef}
          to={"/survey/1"}
          className="h-12 w-12 flex items-center justify-center shrink-0 rounded-xl bg-white transition-colors hover:bg-lightBlue border border-blue"
        >
          <img
            className="-translate-x-[2px]"
            src={arrow}
            width={12}
            height={20}
            alt="arrow"
          />
        </NavLink>

        <button
          onClick={() => fetchData(1, 2, () => navigate("/survey/3"))}
          className={`${
            empty ? "pointer-events-none opacity-80" : ""
          } mx-auto h-12 w-28 flex items-center justify-center shrink-0 rounded-xl font-medium text-white bg-blue transition-colors hover:bg-darkBlue`}
        >
          OK
        </button>

        <button
          ref={rightArrowRef}
          disabled={empty}
          onClick={() => fetchData(1, 2, () => navigate("/survey/3"))}
          className={`${
            empty ? "pointer-events-none opacity-40" : ""
          } h-12 w-12 flex items-center justify-center shrink-0 rounded-xl bg-white transition-colors hover:bg-lightBlue border border-blue`}
        >
          <img
            className="translate-x-[2px] rotate-180"
            src={arrow}
            width={12}
            height={20}
            alt="arrow"
          />
        </button>
      </div>
    </div>
  );
}
