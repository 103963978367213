import React, { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import email from "./../icons/email.svg";
import password from "./../icons/password.svg";
import changeEmail from "./../icons/change-email.svg";

const AccountSettings = () => {

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <section className="settings">
      <div className="container max-w-[1058px]">
        <div className="text-block mt-3 mb-9">
          <h1 className="text-2xl font-bold text-blackPrimary">
            Account settings
          </h1>
          <p className="text-sm font-normal text-themeGray">
            Here's the current update for today.
          </p>
        </div>

        <div className="card-block mb-16 md:mb-20">
          <ul className="flex justify-center flex-wrap gap-5">
            <li className="p-5 md:p-6 rounded-2xl flex flex-col items-start flex-shrink flex-grow basis-full sm:basis-[47%] max-w-[520px] text-black bg-white">
              <div className="h-8 w-full px-2 mb-4 rounded-lg flex items-center bg-blue012">
                <img
                  src={email}
                  className="mr-3"
                  width={24}
                  height={24}
                  alt="email"
                />
                <p className="font-semibold text-sm text-grey">Email</p>
              </div>
              <p className="mb-4 ml-2 text-base text-blackPrimary font-normal">
                tanja@devbrother.com
              </p>
              <button
                onClick={handleClickOpen}
                className="edit-btn ml-2 flex items-center text-grey hover:text-blue transition-all"
              >
                <svg
                  className="mr-3 transition-all"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7333 5.83268L9.78325 13.7826C8.99158 14.5743 6.64156 14.941 6.11656 14.416C5.59156 13.891 5.94989 11.541 6.74156 10.7493L14.6999 2.79099C14.8962 2.57688 15.1338 2.40476 15.3984 2.28501C15.663 2.16526 15.9492 2.10034 16.2396 2.09423C16.5299 2.08812 16.8186 2.1409 17.088 2.24941C17.3574 2.35792 17.6021 2.51992 17.8072 2.72558C18.0123 2.93125 18.1736 3.17633 18.2813 3.44605C18.3891 3.71578 18.4412 4.00453 18.4343 4.2949C18.4273 4.58528 18.3616 4.87127 18.2412 5.13556C18.1207 5.39985 17.9479 5.63701 17.7333 5.83268Z"
                    stroke="#4B5865"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.1665 3.83301H4.99984C4.11578 3.83301 3.26799 4.18419 2.64286 4.80932C2.01775 5.43444 1.6665 6.28228 1.6665 7.16634V15.4997C1.6665 16.3838 2.01775 17.2316 2.64286 17.8567C3.26799 18.4818 4.11578 18.833 4.99984 18.833H14.1665C16.0082 18.833 16.6665 17.333 16.6665 15.4997V11.333"
                    stroke="#4B5865"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.75 4.25L16.25 6.75"
                    stroke="#4B5865"
                    strokeWidth="1.66667"
                  />
                </svg>
                <p className="text-base">Edit</p>
              </button>
            </li>

            <li className="p-5 md:p-6 rounded-2xl flex flex-col items-start flex-shrink flex-grow basis-full sm:basis-[47%] max-w-[520px] text-black bg-white">
              <div className="h-8 w-full px-2 mb-4 rounded-lg flex items-center bg-blue012">
                <img
                  src={password}
                  className="mr-3"
                  width={24}
                  height={24}
                  alt="password"
                />
                <p className="font-semibold text-sm text-grey">Password</p>
              </div>
              <p className="mb-4 ml-2 text-base text-blackPrimary font-normal">
                **********
              </p>
              <button className="edit-btn ml-2 flex items-center text-grey hover:text-blue transition-all">
                <svg
                  className="mr-3 transition-all"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7333 5.83268L9.78325 13.7826C8.99158 14.5743 6.64156 14.941 6.11656 14.416C5.59156 13.891 5.94989 11.541 6.74156 10.7493L14.6999 2.79099C14.8962 2.57688 15.1338 2.40476 15.3984 2.28501C15.663 2.16526 15.9492 2.10034 16.2396 2.09423C16.5299 2.08812 16.8186 2.1409 17.088 2.24941C17.3574 2.35792 17.6021 2.51992 17.8072 2.72558C18.0123 2.93125 18.1736 3.17633 18.2813 3.44605C18.3891 3.71578 18.4412 4.00453 18.4343 4.2949C18.4273 4.58528 18.3616 4.87127 18.2412 5.13556C18.1207 5.39985 17.9479 5.63701 17.7333 5.83268Z"
                    stroke="#4B5865"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.1665 3.83301H4.99984C4.11578 3.83301 3.26799 4.18419 2.64286 4.80932C2.01775 5.43444 1.6665 6.28228 1.6665 7.16634V15.4997C1.6665 16.3838 2.01775 17.2316 2.64286 17.8567C3.26799 18.4818 4.11578 18.833 4.99984 18.833H14.1665C16.0082 18.833 16.6665 17.333 16.6665 15.4997V11.333"
                    stroke="#4B5865"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.75 4.25L16.25 6.75"
                    stroke="#4B5865"
                    strokeWidth="1.66667"
                  />
                </svg>
                <p className="text-base">Edit</p>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "520px",
            width: "100%",
            margin: "18px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: 0,
          }}
        >
          {/* <form className="max-w-[450px] w-full" onSubmit={handleSubmit}> */}
          <form className="max-w-[520px] w-full relative">
            <div className="form-wrap flex flex-col items-start p-5 sm:p-8 border border-lightBlue rounded-lg bg-white">
              <div className="w-full flex flex-col mb-5">
                <p className="flex items-center mb-8 text-xl font-bold text-black">
                  <img
                    className="h-[40px] w-[40px] mr-4"
                    src={changeEmail}
                    width={40}
                    height={40}
                    alt="account email"
                  />
                  Change account email
                </p>
                <label className="mb-2 text-[14px] text-blackPrimary font-normal">
                  New e-mail address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="New e-mail address"
                  required
                  autoFocus
                  value={inputs.email || ""}
                  onChange={handleChange}
                  className="focus:border-blue h-[50px] px-6 pb-[2px] border-[1px] border-gray rounded-lg outline-none placeholder:text-blackPrimary07 placeholder:text-[16px] text-[16px] text-blackPrimary font-normal bg-white"
                />
              </div>
              <div className="w-full flex flex-col mb-8">
                <label className="mb-2 text-[14px] text-blackPrimary font-normal">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  required
                  value={inputs.password || ""}
                  onChange={handleChange}
                  className="focus:border-blue h-[50px] px-6 pb-[2px] border-[1px] border-gray rounded-lg outline-none placeholder:text-blackPrimary07 placeholder:text-[16px] text-[16px] text-blackPrimary font-normal bg-white"
                />
              </div>

              <div className="max-w-[520px] w-full flex justify-center gap-[10px]">
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="h-11 px-2 max-w-[175px] w-full flex justify-center items-center rounded-xl whitespace-nowrap bg-blue hover:bg-darkBlue font-Author font-semibold text-lg text-themeWhite transition-colors"
                >
                  Save new e-mail
                </button>
                <button
                  onClick={handleClose}
                  className="group h-11 max-w-[105px] w-full px-2 flex justify-center items-center rounded-xl text-blackPrimary border-[2px] border-blue bg-white hover:bg-blue hover:text-white transition-colors font-Author text-lg font-medium"
                >
                  Cancel
                </button>
              </div>
            </div>

            <svg
              className="group absolute right-2 top-2 cursor-pointer transition-all"
              onClick={handleClose}
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1932_7076)">
                <circle
                  className="group-hover:stroke-blue"
                  cx="14.375"
                  cy="15.625"
                  r="13.375"
                  stroke="#7F8EA3"
                  strokeOpacity="0.6"
                  strokeWidth="2"
                />
                <path
                  className="group-hover:stroke-blue"
                  d="M21.25 8.75L7.5 22.5"
                  stroke="#7F8EA3"
                  strokeOpacity="0.6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  className="group-hover:stroke-blue"
                  d="M7.5 8.75L21.25 22.5"
                  stroke="#7F8EA3"
                  strokeOpacity="0.6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1932_7076">
                  <rect width="30" height="30" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </form>
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default AccountSettings;
