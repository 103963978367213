import React from "react";

const TimelineAOS = () => {
  return (
    <>      
      <div className="timeline flex items-center mb-10">
        <div className="circleWrapper relative mr-4">
          <div className="circle relative after:content-[''] after:w-[2px] after:h-[190px] after:bottom-[-190px] md:after:h-[190px] md:after:bottom-[-190px] after:absolute after:left-6 after:bg-yellow inline-flex justify-center items-center text-2xl text-black font-semibold h-12 w-12 rounded-full bg-yellow opacity-100">
            1
          </div>
        </div>

        <p className="message max-w-[300px] sm:max-w-[480px] text-white sm:text-lg">
          <span className="font-bold">NO Upfront fees, </span>
          if we didn't find candidate or he didn't pass trial
          <span className="font-bold"> - no payment</span>
        </p>
      </div>

      <div className="timeline flex items-center mb-10">
        <div className="circleWrapper relative mr-4">
          <div className="circle inline-flex justify-center items-center text-2xl text-black font-semibold h-12 w-12 rounded-full bg-yellow opacity-100">
            2
          </div>
        </div>

        <p className="message max-w-[300px] sm:max-w-[480px] text-white sm:text-lg">
          <span className="font-bold">Replacement </span> {"–"} No additional fees for replacement at any moment
          
        </p>
      </div>

      <div className="timeline flex items-center">
        <div className="circleWrapper relative mr-4">
          <div className="circle inline-flex justify-center items-center text-2xl text-black font-semibold h-12 w-12 rounded-full bg-yellow opacity-100">
            3
          </div>
        </div>

        <p className="message max-w-[300px] sm:max-w-[480px] text-white sm:text-lg">
          <span className="font-bold">Internal Tech Team </span> {"–"} Internal dev team makes tech interviews {"–"} you
            receive 2-3 candidates and decide the best fit for you
        </p>
      </div>      
    </>
  );
};

export default TimelineAOS;
